// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import '../Assets/Css/Style.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import { useNavigate } from 'react-router';
// import LogoutModal from '../Auth/LogoutModal';

// const Sidebar = () => {
//     const navigate = useNavigate();
//     const [isExpanded, setIsExpanded] = useState(false);
//     const [openSection, setOpenSection] = useState(null);
//     const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

//     // Toggle sidebar
//     const handleToggle = () => {
//         setIsExpanded(!isExpanded);
//     };

//     const logout = () => {
//         localStorage.clear('token');
//         navigate('/');
//     };

//     // Toggle dropdowns
//     const handleSectionToggle = (section) => {
//         setOpenSection(openSection === section ? null : section);
//     };

//     return (
//         <>

//             <div className={`sidebar__wrap ${isExpanded ? 'expand' : ''}`}>
//                 <div className="wrapper">
//                     <aside id="sidebar" className={isExpanded ? 'expand' : ''}>
//                         <div className="d-flex">
//                             <button className="toggle-btn" type="button" onClick={handleToggle}>
//                                 <i class="bi bi-toggles"></i>
//                             </button>
//                             <div className="sidebar-logo">
//                                 <a href="#">Future Trader</a>
//                             </div>
//                         </div>
//                         <ul className="sidebar-nav">
//                             <li className="sidebar-item">
//                                 <NavLink to='/dashboard' className="sidebar-link">
//                                     <i className="bi bi-speedometer2" />
//                                     <span>Dashboard</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <NavLink to='/Tradermember' className="sidebar-link">
//                                     <i className="bi bi-person" />
//                                     <span>Member</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <NavLink to='/activity' className="sidebar-link">
//                                 <i class="bi bi-alexa"></i>
//                                     <span>Activity</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href="#" className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('auth')}>
//                                     <i className="bi bi-person" />
//                                     <span>Future Trade</span>
//                                 </a>
//                                 <ul id="auth" className={`sidebar-dropdown list-unstyled ${openSection === 'auth' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/new_trade' className=' navlink sidebar-link collapsed'>
//                                             New Trade
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/closeposition' className=' navlink sidebar-link collapsed'>
//                                         Closed Positions
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/tradeopen' className=' navlink sidebar-link collapsed'>
//                                         trades
//                                         </NavLink>
//                                     </li>

//                                      <li className="sidebar-item outcome">
//                                 <NavLink to='/fundTransfer' className=' navlink sidebar-link collapsed'>
//                                     FundTransfer
//                                     </NavLink>
//                                 </li>
//                                      <li className="sidebar-item outcome">
//                                 <NavLink to='/fundertranction' className=' navlink sidebar-link collapsed'>
//                                 Fund Tranctation
//                                     </NavLink>
//                                 </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/Positionhistory' className=' navlink sidebar-link collapsed'>
//                                             Order
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href='#' className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('multi')}>
//                                     <i className="bi bi-person-circle   " />
//                                     <span>Spot Trade</span>
//                                 </a>
//                                 <ul id="multi" className={`sidebar-dropdown list-unstyled ${openSection === 'multi' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/spottrade' className=' navlink sidebar-link collapsed'>
//                                             New Trade
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/order' className=' navlink sidebar-link collapsed'>
//                                             Order
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/trader' className=' navlink sidebar-link collapsed'>
//                                         trades
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>

//                             <li>
//                                 <a href="#" className="sidebar-link" onClick={() => setIsLogoutModalOpen(true)}>
//                                     <i class="bi bi-box-arrow-left"></i>
//                                     <span>Logout</span>
//                                 </a>
//                             </li>
//                         </ul>
//                     </aside>
//                 </div>

//                 <LogoutModal
//                     isOpen={isLogoutModalOpen}
//                     onCancel={() => setIsLogoutModalOpen(false)}
//                     onConfirm={() => {
//                         setIsLogoutModalOpen(false);
//                         logout();
//                     }}
//                 />

//             </div>

//         </>
//     );
// };

// export default Sidebar;

// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import '../Assets/Css/Style.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import { useNavigate } from 'react-router';
// import LogoutModal from '../Auth/LogoutModal';

// const Sidebar = () => {
//     const navigate = useNavigate();
//     const [isExpanded, setIsExpanded] = useState(false);
//     const [openSection, setOpenSection] = useState(null);
//     const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

//     const handleToggle = () => {
//         setIsExpanded(!isExpanded);
//     };

//     const logout = () => {
//         localStorage.clear('token');
//         navigate('/');
//     };

//     const handleSectionToggle = (section) => {
//         setOpenSection(openSection === section ? null : section);
//     };

//     return (
//         <>

//             <div className={`sidebar__wrap ${isExpanded ? 'expand' : ''}`}>
//                 <div className="wrapper">
//                     <aside id="sidebar" className={isExpanded ? 'expand' : ''}>
//                         <div className="d-flex">
//                             <button className="toggle-btn" type="button" onClick={handleToggle}>
//                                 <i class="bi bi-toggles"></i>
//                             </button>
//                             <div className="sidebar-logo">
//                                 <a href="#">Future Trader</a>
//                             </div>
//                         </div>
//                         <ul className="sidebar-nav">
//                             <li className="sidebar-item">
//                                 <NavLink to='/dashboard' className="sidebar-link">
//                                     <i className="bi bi-speedometer2" />
//                                     <span>Dashboard</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <NavLink to='/Tradermember' className="sidebar-link">
//                                     <i className="bi bi-person" />
//                                     <span>Member</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <NavLink to='/activity' className="sidebar-link">
//                                 <i class="bi bi-alexa"></i>
//                                     <span>Activity</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href="#" className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('auth')}>
//                                     <i className="bi bi-person" />
//                                     <span>Future Trade</span>
//                                 </a>
//                                 <ul id="auth" className={`sidebar-dropdown list-unstyled ${openSection === 'auth' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/new_trade' className=' navlink sidebar-link collapsed'>
//                                             New Trade
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/closeposition' className=' navlink sidebar-link collapsed'>
//                                         Closed Positions
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/tradeopen' className=' navlink sidebar-link collapsed'>
//                                         trades
//                                         </NavLink>
//                                     </li>
//                                     {/* <li className="sidebar-item outcome">
//                                 <NavLink to='/closeposition' className=' navlink sidebar-link collapsed'>
//                                     Close Postition
//                                     </NavLink>
//                                 </li> */}
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/Positionhistory' className=' navlink sidebar-link collapsed'>
//                                             Order
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href='#' className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('multi')}>
//                                     <i className="bi bi-person-circle   " />
//                                     <span>Spot Trade</span>
//                                 </a>
//                                 <ul id="multi" className={`sidebar-dropdown list-unstyled ${openSection === 'multi' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/spottrade' className=' navlink sidebar-link collapsed'>
//                                             New Trade
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/order' className=' navlink sidebar-link collapsed'>
//                                             Order
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/trader' className=' navlink sidebar-link collapsed'>
//                                         trades
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>

//                             <li>
//                                 <a href="#" className="sidebar-link" onClick={() => setIsLogoutModalOpen(true)}>
//                                     <i class="bi bi-box-arrow-left"></i>
//                                     <span>Logout</span>
//                                 </a>
//                             </li>
//                         </ul>
//                     </aside>
//                 </div>

//                 <LogoutModal
//                     isOpen={isLogoutModalOpen}
//                     onCancel={() => setIsLogoutModalOpen(false)}
//                     onConfirm={() => {
//                         setIsLogoutModalOpen(false);
//                         logout();
//                     }}
//                 />

//             </div>
//             <div className="home_content iweufhoiewugho">
//         <div className={`text ${isContentActive ? "sidebar-hideshow" : "text"} `}>
//           <div className="row header">
//             <div className="col-8 d-flex align-c">
//             <NavLink to="/">
//                 <img src={Bitnettologo} className="logo1" alt="" />
//               </NavLink>
//               <TiThMenu id="btn" className="toggal_btn" onClick={toggleSidebar} />
//               <img src="./images/phone-logo.png" className="phon-logo" alt="" />
//             </div>
//             <div className="col-4 right header-icon align-c">
//               {/* <a href="#">
//                 {/* <FaBell className="b head_icon" />
//               </a> */}
//               <button className="olpa" onClick={handleLogout}>
//                 Logout
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//         </>
//     );
// };

// export default Sidebar;

// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import '../Assets/Css/Style.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import { useNavigate } from 'react-router';
// import LogoutModal from '../Auth/LogoutModal';
// import { TiThMenu } from "react-icons/ti"; // For the toggle button in header

// const Sidebar = () => {
//     const navigate = useNavigate();
//     const [isExpanded, setIsExpanded] = useState(false);
//     const [openSection, setOpenSection] = useState(null);
//     const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

//     // Toggle Sidebar Expand/Collapse
//     const handleToggle = () => {
//         setIsExpanded(!isExpanded);
//     };

//     // Logout function
//     const logout = () => {
//         localStorage.clear('token');
//         navigate('/');
//     };

//     // Toggle Section within Sidebar
//     const handleSectionToggle = (section) => {
//         setOpenSection(openSection === section ? null : section);
//     };

//     return (
//         <>

//             <div className={`sidebar__wrap ${isExpanded ? 'expand' : ''}`}>
//                 <div className="wrapper">
//                     <aside id="sidebar" className={isExpanded ? 'expand' : ''}>
//                         <div className="d-flex">
//                             <button className="toggle-btn" type="button" onClick={handleToggle}>
//                                 <i className="bi bi-toggles"></i>
//                             </button>
//                             <div className="sidebar-logo">
//                                 <a href="#">Future Trader</a>
//                             </div>
//                         </div>
//                         <ul className="sidebar-nav">
//                             <li className="sidebar-item">
//                                 <NavLink to='/dashboard' className="sidebar-link">
//                                     <i className="bi bi-speedometer2" />
//                                     <span>Dashboard</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <NavLink to='/Tradermember' className="sidebar-link">
//                                     <i className="bi bi-person" />
//                                     <span>Member</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <NavLink to='/activity' className="sidebar-link">
//                                     <i className="bi bi-alexa"></i>
//                                     <span>Activity</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href="#" className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('auth')}>
//                                     <i className="bi bi-person" />
//                                     <span>Future Trade</span>
//                                 </a>
//                                 <ul id="auth" className={`sidebar-dropdown list-unstyled ${openSection === 'auth' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/new_trade' className='navlink sidebar-link collapsed'>
//                                             New Trade
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/closeposition' className='navlink sidebar-link collapsed'>
//                                             Closed Positions
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/tradeopen' className='navlink sidebar-link collapsed'>
//                                             Trades
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/Positionhistory' className='navlink sidebar-link collapsed'>
//                                             Order
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href='#' className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('multi')}>
//                                     <i className="bi bi-person-circle" />
//                                     <span>Spot Trade</span>
//                                 </a>
//                                 <ul id="multi" className={`sidebar-dropdown list-unstyled ${openSection === 'multi' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/spottrade' className='navlink sidebar-link collapsed'>
//                                             New Trade
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/order' className='navlink sidebar-link collapsed'>
//                                             Order
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/trader' className='navlink sidebar-link collapsed'>
//                                             Trades
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>
//                             <li>
//                                 <a href="#" className="sidebar-link" onClick={() => setIsLogoutModalOpen(true)}>
//                                     <i className="bi bi-box-arrow-left"></i>
//                                     <span>Logout</span>
//                                 </a>
//                             </li>
//                         </ul>
//                     </aside>
//                 </div>

//                 {/* Logout Modal */}
//                 <LogoutModal
//                     isOpen={isLogoutModalOpen}
//                     onCancel={() => setIsLogoutModalOpen(false)}
//                     onConfirm={() => {
//                         setIsLogoutModalOpen(false);
//                         logout();
//                     }}
//                 />
//             </div>

//             {/* Header */}
//             <div className="home_content">
//                 <div className={`text ${isExpanded ? "sidebar-hideshow" : "text"}`}>
//                     <div className="row header">
//                         <div className="col-8 d-flex align-c">
//                             <NavLink to="/">
//                                 <img src="path-to-logo" className="logo1" alt="Logo" />
//                             </NavLink>
//                             <TiThMenu id="btn" className="toggal_btn" onClick={handleToggle} />
//                             <img src="./images/phone-logo.png" className="phon-logo" alt="Phone Logo" />
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Sidebar;

// import React, { useState ,useEffect} from 'react';
// import { NavLink } from 'react-router-dom';
// import '../Assets/Css/Style.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import { useNavigate } from 'react-router';
// import LogoutModal from '../Auth/LogoutModal';
// import logo from '../Assets/logo.png'
// import { TiThMenu } from 'react-icons/ti';

// const Sidebar = () => {
//     const navigate = useNavigate();
//     const [isExpanded, setIsExpanded] = useState(true);
//     const [openSection, setOpenSection] = useState(null);
//     const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
//     const [isMobile, setIsMobile] = useState(false);

//         useEffect(() => {
//                 const handleResize = () => {
//                     if (window.innerWidth <= 756) {
//                         setIsMobile(true);
//                     } else {
//                         setIsMobile(false);
//                     }
//                 };

//                 window.addEventListener('resize', handleResize);

//                 handleResize();

//                 return () => {
//                     window.removeEventListener('resize', handleResize);
//                 };
//             }, []);

//     const handleToggle = () => {
//         setIsExpanded(!isExpanded);
//     };

//     const logout = () => {
//         localStorage.clear('token');
//         navigate('/');
//     };

//     // Toggle dropdowns
//     const handleSectionToggle = (section) => {
//         setOpenSection(openSection === section ? null : section);
//     };

//     const handleNavLinkClick = () => {
//                 if (isMobile) {
//                     setIsExpanded(false);
//                 }
//             }
//     return (
//         <>

//             <div className={`sidebar__wrap ${isExpanded ? 'expand' : ''}`}>
//                 <div className="wrapper">
//                 {/* {(!isMobile) &&( */}
//                     <aside id="sidebar" className={isExpanded ? 'expand' : ''}>
//                     {(!isMobile) &&(
//                         <>
//                         <div className="d-flex">
//                              {/* <button className="toggle-btn" type="button" onClick={handleToggle}> </button> */}
//                              <button className="toggle-btn" type="button"  >
//                                 <i class="bi bi-toggles" onClick={handleToggle}> </i>
//                             </button>
//                             <div className="sidebar-logo">
//                             <img className="welcome" src={logo} alt="" />
//                             </div>
//                         </div>
//                         <ul className="sidebar-nav">
//                             <li className="sidebar-item">
//                                 <NavLink to='/dashboard' className="sidebar-link">
//                                     <i className="bi bi-speedometer2" />
//                                     <span>Dashboard</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href="#" className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('auth')}>
//                                     <i className="bi bi-person" />
//                                     <span>Member Management</span>
//                                 </a>
//                                 <ul id="auth" className={`sidebar-dropdown list-unstyled ${openSection === 'auth' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/Tradermember' className=' navlink sidebar-link collapsed'>
//                                         All Members
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/Paidmember' className=' navlink sidebar-link collapsed'>
//                                         Paid Members
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/Activatedmembers' className=' navlink sidebar-link collapsed'>
//                                         Api Activated Members
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>
//                             {/* <li className="sidebar-item">
//                                 <NavLink to='/Tradermember' className="sidebar-link">
//                                     <i className="bi bi-person" />
//                                     <span>Member</span>
//                                 </NavLink>
//                             </li> */}
//                             <li className="sidebar-item">
//                                 <NavLink to='/activity' className="sidebar-link">
//                                 <i class="bi bi-alexa"></i>
//                                     <span>Activity</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href="#" className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('authi')}>
//                                     <i className="bi bi-person" />
//                                     <span>Future Trade</span>
//                                 </a>
//                                 <ul id="auth" className={`sidebar-dropdown list-unstyled ${openSection === 'authi' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/new_trade' className=' navlink sidebar-link collapsed'>
//                                             New Trade
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/closeposition' className=' navlink sidebar-link collapsed'>
//                                         Closed Positions
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/tradeopen' className=' navlink sidebar-link collapsed'>
//                                         trades
//                                         </NavLink>
//                                     </li>

//                                      <li className="sidebar-item outcome">
//                                 <NavLink to='/fundTransfer' className=' navlink sidebar-link collapsed'>
//                                     FundTransfer
//                                     </NavLink>
//                                 </li>
//                                      <li className="sidebar-item outcome">
//                                 <NavLink to='/fundertranction' className=' navlink sidebar-link collapsed'>
//                                 Fund Tranctation
//                                     </NavLink>
//                                 </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/Positionhistory' className=' navlink sidebar-link collapsed'>
//                                             Order
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href='#' className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('multi')}>
//                                     <i className="bi bi-person-circle   " />
//                                     <span>Spot Trade</span>
//                                 </a>
//                                 <ul id="multi" className={`sidebar-dropdown list-unstyled ${openSection === 'multi' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/spottrade' className=' navlink sidebar-link collapsed'>
//                                             New Trade
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/order' className=' navlink sidebar-link collapsed'>
//                                             Order
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/trader' className=' navlink sidebar-link collapsed'>
//                                         trades
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>

//                             <li>
//                                 <a href="#" className="sidebar-link" onClick={() => setIsLogoutModalOpen(true)}>
//                                     <i class="bi bi-box-arrow-left"></i>
//                                     <span>Logout</span>
//                                 </a>
//                             </li>
//                         </ul>
//                         </>
//                     )}
//                     </aside>
//                 {/* )} */}
//                 </div>
//                 <div className="home_content">
//                  <div className={`text ${isExpanded ? "sidebar-hideshow" : "text"}`}>
//                      <div className="row header">
//                         <div className="col-md-8 d-flex align-c">
//                              <NavLink to="/" onClick={handleNavLinkClick}>
//                                  {/* <img src={logo} className="logo1" alt="Logo" /> */}
//                              </NavLink>

//                              {isMobile && (
//                                 <TiThMenu
//                                     id="btn"
//                                     className="toggle_btn"
//                                     onClick={handleToggle}
//                                 />
//                             )}

//                         </div>
//                     </div>
//                 </div>
//             </div>

//                 <LogoutModal
//                     isOpen={isLogoutModalOpen}
//                     onCancel={() => setIsLogoutModalOpen(false)}
//                     onConfirm={() => {
//                         setIsLogoutModalOpen(false);
//                         logout();
//                     }}
//                 />

//             </div>

//         </>
//     );
// };

// export default Sidebar;

// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import '../Assets/Css/Style.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import { useNavigate } from 'react-router';
// import LogoutModal from '../Auth/LogoutModal';

// const Sidebar = () => {
//     const navigate = useNavigate();
//     const [isExpanded, setIsExpanded] = useState(false);
//     const [openSection, setOpenSection] = useState(null);
//     const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

//     const handleToggle = () => {
//         setIsExpanded(!isExpanded);
//     };

//     const logout = () => {
//         localStorage.clear('token');
//         navigate('/');
//     };

//     const handleSectionToggle = (section) => {
//         setOpenSection(openSection === section ? null : section);
//     };

//     return (
//         <>

//             <div className={`sidebar__wrap ${isExpanded ? 'expand' : ''}`}>
//                 <div className="wrapper">
//                     <aside id="sidebar" className={isExpanded ? 'expand' : ''}>
//                         <div className="d-flex">
//                             <button className="toggle-btn" type="button" onClick={handleToggle}>
//                                 <i class="bi bi-toggles"></i>
//                             </button>
//                             <div className="sidebar-logo">
//                                 <a href="#">Future Trader</a>
//                             </div>
//                         </div>
//                         <ul className="sidebar-nav">
//                             <li className="sidebar-item">
//                                 <NavLink to='/dashboard' className="sidebar-link">
//                                     <i className="bi bi-speedometer2" />
//                                     <span>Dashboard</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <NavLink to='/Tradermember' className="sidebar-link">
//                                     <i className="bi bi-person" />
//                                     <span>Member</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <NavLink to='/activity' className="sidebar-link">
//                                 <i class="bi bi-alexa"></i>
//                                     <span>Activity</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href="#" className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('auth')}>
//                                     <i className="bi bi-person" />
//                                     <span>Future Trade</span>
//                                 </a>
//                                 <ul id="auth" className={`sidebar-dropdown list-unstyled ${openSection === 'auth' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/new_trade' className=' navlink sidebar-link collapsed'>
//                                             New Trade
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/closeposition' className=' navlink sidebar-link collapsed'>
//                                         Closed Positions
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/tradeopen' className=' navlink sidebar-link collapsed'>
//                                         trades
//                                         </NavLink>
//                                     </li>
//                                     {/* <li className="sidebar-item outcome">
//                                 <NavLink to='/closeposition' className=' navlink sidebar-link collapsed'>
//                                     Close Postition
//                                     </NavLink>
//                                 </li> */}
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/Positionhistory' className=' navlink sidebar-link collapsed'>
//                                             Order
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href='#' className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('multi')}>
//                                     <i className="bi bi-person-circle   " />
//                                     <span>Spot Trade</span>
//                                 </a>
//                                 <ul id="multi" className={`sidebar-dropdown list-unstyled ${openSection === 'multi' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/spottrade' className=' navlink sidebar-link collapsed'>
//                                             New Trade
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/order' className=' navlink sidebar-link collapsed'>
//                                             Order
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/trader' className=' navlink sidebar-link collapsed'>
//                                         trades
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>

//                             <li>
//                                 <a href="#" className="sidebar-link" onClick={() => setIsLogoutModalOpen(true)}>
//                                     <i class="bi bi-box-arrow-left"></i>
//                                     <span>Logout</span>
//                                 </a>
//                             </li>
//                         </ul>
//                     </aside>
//                 </div>

//                 <LogoutModal
//                     isOpen={isLogoutModalOpen}
//                     onCancel={() => setIsLogoutModalOpen(false)}
//                     onConfirm={() => {
//                         setIsLogoutModalOpen(false);
//                         logout();
//                     }}
//                 />

//             </div>
//             <div className="home_content iweufhoiewugho">
//         <div className={`text ${isContentActive ? "sidebar-hideshow" : "text"} `}>
//           <div className="row header">
//             <div className="col-8 d-flex align-c">
//             <NavLink to="/">
//                 <img src={Bitnettologo} className="logo1" alt="" />
//               </NavLink>
//               <TiThMenu id="btn" className="toggal_btn" onClick={toggleSidebar} />
//               <img src="./images/phone-logo.png" className="phon-logo" alt="" />
//             </div>
//             <div className="col-4 right header-icon align-c">
//               {/* <a href="#">
//                 {/* <FaBell className="b head_icon" />
//               </a> */}
//               <button className="olpa" onClick={handleLogout}>
//                 Logout
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//         </>
//     );
// };

// export default Sidebar;

// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import '../Assets/Css/Style.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import { useNavigate } from 'react-router';
// import LogoutModal from '../Auth/LogoutModal';
// import { TiThMenu } from "react-icons/ti"; // For the toggle button in header

// const Sidebar = () => {
//     const navigate = useNavigate();
//     const [isExpanded, setIsExpanded] = useState(false);
//     const [openSection, setOpenSection] = useState(null);
//     const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

//     // Toggle Sidebar Expand/Collapse
//     const handleToggle = () => {
//         setIsExpanded(!isExpanded);
//     };

//     // Logout function
//     const logout = () => {
//         localStorage.clear('token');
//         navigate('/');
//     };

//     // Toggle Section within Sidebar
//     const handleSectionToggle = (section) => {
//         setOpenSection(openSection === section ? null : section);
//     };

//     return (
//         <>

//             <div className={`sidebar__wrap ${isExpanded ? 'expand' : ''}`}>
//                 <div className="wrapper">
//                     <aside id="sidebar" className={isExpanded ? 'expand' : ''}>
//                         <div className="d-flex">
//                             <button className="toggle-btn" type="button" onClick={handleToggle}>
//                                 <i className="bi bi-toggles"></i>
//                             </button>
//                             <div className="sidebar-logo">
//                                 <a href="#">Future Trader</a>
//                             </div>
//                         </div>
//                         <ul className="sidebar-nav">
//                             <li className="sidebar-item">
//                                 <NavLink to='/dashboard' className="sidebar-link">
//                                     <i className="bi bi-speedometer2" />
//                                     <span>Dashboard</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <NavLink to='/Tradermember' className="sidebar-link">
//                                     <i className="bi bi-person" />
//                                     <span>Member</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <NavLink to='/activity' className="sidebar-link">
//                                     <i className="bi bi-alexa"></i>
//                                     <span>Activity</span>
//                                 </NavLink>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href="#" className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('auth')}>
//                                     <i className="bi bi-person" />
//                                     <span>Future Trade</span>
//                                 </a>
//                                 <ul id="auth" className={`sidebar-dropdown list-unstyled ${openSection === 'auth' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/new_trade' className='navlink sidebar-link collapsed'>
//                                             New Trade
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/closeposition' className='navlink sidebar-link collapsed'>
//                                             Closed Positions
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/tradeopen' className='navlink sidebar-link collapsed'>
//                                             Trades
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/Positionhistory' className='navlink sidebar-link collapsed'>
//                                             Order
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>
//                             <li className="sidebar-item">
//                                 <a href='#' className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('multi')}>
//                                     <i className="bi bi-person-circle" />
//                                     <span>Spot Trade</span>
//                                 </a>
//                                 <ul id="multi" className={`sidebar-dropdown list-unstyled ${openSection === 'multi' ? 'show' : 'collapse'}`}>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/spottrade' className='navlink sidebar-link collapsed'>
//                                             New Trade
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/order' className='navlink sidebar-link collapsed'>
//                                             Order
//                                         </NavLink>
//                                     </li>
//                                     <li className="sidebar-item outcome">
//                                         <NavLink to='/trader' className='navlink sidebar-link collapsed'>
//                                             Trades
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             </li>
//                             <li>
//                                 <a href="#" className="sidebar-link" onClick={() => setIsLogoutModalOpen(true)}>
//                                     <i className="bi bi-box-arrow-left"></i>
//                                     <span>Logout</span>
//                                 </a>
//                             </li>
//                         </ul>
//                     </aside>
//                 </div>

//                 {/* Logout Modal */}
//                 <LogoutModal
//                     isOpen={isLogoutModalOpen}
//                     onCancel={() => setIsLogoutModalOpen(false)}
//                     onConfirm={() => {
//                         setIsLogoutModalOpen(false);
//                         logout();
//                     }}
//                 />
//             </div>

//             {/* Header */}
//             <div className="home_content">
//                 <div className={`text ${isExpanded ? "sidebar-hideshow" : "text"}`}>
//                     <div className="row header">
//                         <div className="col-8 d-flex align-c">
//                             <NavLink to="/">
//                                 <img src="path-to-logo" className="logo1" alt="Logo" />
//                             </NavLink>
//                             <TiThMenu id="btn" className="toggal_btn" onClick={handleToggle} />
//                             <img src="./images/phone-logo.png" className="phon-logo" alt="Phone Logo" />
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Sidebar;

// import React, { useState, useEffect } from "react";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import "../Assets/Css/Style.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// // import { useNavigate } from 'react-router';
// import LogoutModal from "../Auth/LogoutModal";
// import { TiThMenu } from "react-icons/ti";
// import logo from "../Assets/logo.png";
// import { SiFuturelearn } from "react-icons/si";
// import { RiRefundLine } from "react-icons/ri";
// import { FaHubspot } from "react-icons/fa";
// import { GiProfit } from "react-icons/gi";

// const Sidebar = () => {
//   const navigate = useNavigate();
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [openSection, setOpenSection] = useState(null);
//   const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 756) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };

//     window.addEventListener("resize", handleResize);

//     handleResize();

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleToggle = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const logout = () => {
//     localStorage.clear("token");
//     navigate("/");
//   };

//   const handleSectionToggle = (section) => {
//     setOpenSection(openSection === section ? null : section);
//   };
//   const handleNavLinkClick = () => {
//     if (isMobile) {
//       setIsExpanded(false);
//     }
//     setOpenSection(null);
//   };

//   return (
//     <>
//       {/* Sidebar wrapper */}
//       {(!isMobile || isExpanded) && (
//         <div className="sidebar__wrap">
//           <div className="wrapper">
//             <div id="sidebar" className={isExpanded ? "expand" : ""}>
//               <div className="d-flex">
//                 {/* <div className="sidebar-logo">
//                                     <a href="#">Future Trader</a>
//                                 </div> */}
//                 <button
//                   className="toggle-btn"
//                   type="button"
//                   onClick={handleToggle}
//                 >
//                   <i className="bi bi-toggles"></i>
//                   <img src={logo} className="phon-logo" alt="Phone Logo" />
//                 </button>
//               </div>
//               <ul className="sidebar-nav">
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/dashboard"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     <i className="bi bi-speedometer2" />
//                     <span>Dashboard</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("multii")}
//                   >
//                     <i className="bi bi-person-circle" />
//                     <span>Member Management</span>
//                   </a>
//                   <ul
//                     id="multii"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "multii" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/Tradermember"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         All Members
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/Paidmember"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Paid Members
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/Activatedmembers"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         API Activated Members
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("bannerManage")}
//                   >
//                     <i className="bi bi-images" />
//                     <span>Banner Management</span>
//                   </a>
//                   <ul
//                     id="banner"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "bannerManage" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/bannerAdd"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Add Banner
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/banners"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Banners
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 {/* <li className="sidebar-item">
//                                         <NavLink to='/Tradermember' className="sidebar-link" onClick={handleNavLinkClick}>
//                                             <i className="bi bi-person" />
//                                             <span>Member</span>
//                                         </NavLink>
//                                     </li> */}
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/activity"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     <i className="bi bi-alexa"></i>
//                     <span>Activity</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("auth")}
//                   >
//                     <SiFuturelearn />
//                     <span>Future Trade</span>
//                   </a>
//                   <ul
//                     id="auth"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "auth" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/new_trade"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         New Trade
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/closeposition"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Closed Positions
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/tradeopen"
//                         className="navlink sidebar-link collapsed dropdown-toggle"
//                         type="button"
//                         data-bs-toggle="dropdown"
//                         aria-expanded="false"
//                         //  onClick={handleNavLinkClick}
//                       >
//                         Trades
//                       </NavLink>
//                       <ul class="ms-2 dropdown-menu">
//                         <li>
//                           <Link  class="dropdown-item" to="/open-trade">
//                             Open Trade
//                           </Link>
//                         </li>
//                         <li>
//                           <Link class="dropdown-item" to="/close-trade">
//                             Close Trade
//                           </Link>
//                         </li>
//                       </ul>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/Positionhistory"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Order
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/batch"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Batchs
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/profitdisbrustion"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Profit Distribution
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("authi")}
//                   >
//                     <RiRefundLine />
//                     <span> Fund Transfer</span>
//                   </a>
//                   <ul
//                     id="auth"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "authi" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/fundTransfer"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Fund Tranfer
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/fundertranction"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Fund Tranfer History
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/alltranction"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     <i className="bi bi-alexa"></i>
//                     <span>All Transactions</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/bonus"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     <GiProfit/>
//                     {/* <i className="bi bi-alexa"></i> */}
//                     <span>Bonus</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/tradefee"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     {/* <GiProfit/> */}
//                     <i className="bi bi-credit-card"></i>
//                     <span>Trade Fee</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("multi")}
//                   >
//                     <FaHubspot />
//                     <span>Spot Trade</span>
//                   </a>
//                   <ul
//                     id="multi"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "multi" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/spottrade"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         New Trade
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/order"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Order
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/trader"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Trades
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <a
//                     href="#"
//                     className="sidebar-link"
//                     onClick={() => setIsLogoutModalOpen(true)}
//                   >
//                     <i className="bi bi-box-arrow-left"></i>
//                     <span>Logout</span>
//                   </a>
//                 </li>
//               </ul>
//               {/* </aside> */}
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Header with Toggle Button */}
//       <div className="home_content">
//         <div className={`text ${isExpanded ? "sidebar-hideshow" : "text"}`}>
//           <div className="row header">
//             <div className="col-md-8 d-flex align-c">
//               <NavLink to="/" onClick={handleNavLinkClick}>
//                 {/* <img src={logo} className="logo1" alt="Logo" /> */}
//               </NavLink>

//               {isMobile && (
//                 <TiThMenu
//                   id="btn"
//                   className="toggle_btn"
//                   onClick={handleToggle}
//                 />
//               )}
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Logout Modal */}
//       <LogoutModal
//         isOpen={isLogoutModalOpen}
//         onCancel={() => setIsLogoutModalOpen(false)}
//         onConfirm={() => {
//           setIsLogoutModalOpen(false);
//           logout();
//         }}
//       />
//     </>
//   );
// };

// export default Sidebar;

// import React, { useState, useEffect } from 'react';
// import { NavLink } from 'react-router-dom';
// import '../Assets/Css/Style.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import { useNavigate } from 'react-router';
// import LogoutModal from '../Auth/LogoutModal';
// import logo from '../Assets/logo.png';
// import { TiThMenu } from 'react-icons/ti';

// const Sidebar = () => {
//     const navigate = useNavigate();
//     const [isExpanded, setIsExpanded] = useState(true);
//     const [openSection, setOpenSection] = useState(null);
//     const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
//     const [isMobile, setIsMobile] = useState(false);

//     useEffect(() => {
//         const handleResize = () => {
//             if (window.innerWidth <= 756) {
//                 setIsMobile(true); // Set mobile view if width is <= 756px
//             } else {
//                 setIsMobile(false); // Set desktop view if width is > 756px
//             }
//         };

//         window.addEventListener('resize', handleResize);

//         handleResize(); // Initial check

//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     const handleToggle = () => {
//         setIsExpanded(!isExpanded);
//     };

//     const logout = () => {
//         localStorage.clear('token');
//         navigate('/');
//     };

//     const handleSectionToggle = (section) => {
//         setOpenSection(openSection === section ? null : section);
//     };

//     const handleNavLinkClick = () => {
//         if (isMobile) {
//             setIsExpanded(false);
//         }
//     };

//     return (
//         <>
//             <div className={`sidebar__wrap ${isExpanded ? 'expand' : ''}`}>
//                 <div className="wrapper">

//                     {!isMobile && (
//                         <aside id="sidebar" className={isExpanded ? 'expand' : ''}>
//                             <div className="d-flex">
//                                 <button className="toggle-btn" type="button">
//                                     <i className="bi bi-toggles" onClick={handleToggle}></i>
//                                 </button>
//                                 <div className="sidebar-logo">
//                                     <img className="welcome" src={logo} alt="Logo" />
//                                 </div>
//                             </div>

//                             <ul className="sidebar-nav">
//                                 <li className="sidebar-item">
//                                     <NavLink to="/dashboard" className="sidebar-link">
//                                         <i className="bi bi-speedometer2" />
//                                         <span>Dashboard</span>
//                                     </NavLink>
//                                 </li>
//                                 <li className="sidebar-item">
//                                     <a
//                                         href="#"
//                                         className="sidebar-link collapsed has-dropdown"
//                                         onClick={() => handleSectionToggle('auth')}
//                                     >
//                                         <i className="bi bi-person" />
//                                         <span>Member Management</span>
//                                     </a>
//                                     <ul
//                                         id="auth"
//                                         className={`sidebar-dropdown list-unstyled ${
//                                             openSection === 'auth' ? 'show' : 'collapse'
//                                         }`}
//                                     >
//                                         <li className="sidebar-item outcome">
//                                             <NavLink to="/Tradermember" className="navlink sidebar-link collapsed">
//                                                 All Members
//                                             </NavLink>
//                                         </li>
//                                         <li className="sidebar-item outcome">
//                                             <NavLink to="/Paidmember" className="navlink sidebar-link collapsed">
//                                                 Paid Members
//                                             </NavLink>
//                                         </li>
//                                         <li className="sidebar-item outcome">
//                                             <NavLink to="/Activatedmembers" className="navlink sidebar-link collapsed">
//                                                 Api Activated Members
//                                             </NavLink>
//                                         </li>
//                                     </ul>
//                                 </li>
//                                 <li className="sidebar-item">
//                                     <NavLink to="/activity" className="sidebar-link">
//                                         <i className="bi bi-alexa" />
//                                         <span>Activity</span>
//                                     </NavLink>
//                                 </li>

//                                 <li>
//                                     <a href="#" className="sidebar-link" onClick={() => setIsLogoutModalOpen(true)}>
//                                         <i className="bi bi-box-arrow-left" />
//                                         <span>Logout</span>
//                                     </a>
//                                 </li>
//                             </ul>
//                         </aside>
//                     )}

//                     {isMobile && (
//                         <div className="home_content">
//                             <div className={`text ${isExpanded ? 'sidebar-hideshow' : 'text'}`}>
//                                 <div className="row header">
//                                     <div className="col-md-8 d-flex align-c">
//                                         <NavLink to="/" onClick={handleNavLinkClick}>
//                                             {/* Add logo or any other header content */}
//                                         </NavLink>

//                                         <TiThMenu
//                                             id="btn"
//                                             className="toggle_btn"
//                                             onClick={handleToggle}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </div>

//                 {/* Logout Modal */}
//                 <LogoutModal
//                     isOpen={isLogoutModalOpen}
//                     onCancel={() => setIsLogoutModalOpen(false)}
//                     onConfirm={() => {
//                         setIsLogoutModalOpen(false);
//                         logout();
//                     }}
//                 />
//             </div>
//         </>
//     );
// };

// export default Sidebar;
// Sidebar.js

// import React, { useState, useEffect } from "react";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import LogoutModal from "../Auth/LogoutModal";
// import { TiThMenu } from "react-icons/ti";
// import { SiFuturelearn } from "react-icons/si";
// import { RiRefundLine } from "react-icons/ri";
// import { GiProfit } from "react-icons/gi";
// import { FaHubspot } from "react-icons/fa";
// import logo from "../Assets/logo.png";

// const Sidebar = () => {
//   const navigate = useNavigate();
//   const [isExpanded, setIsExpanded] = useState(true);
//   const [openSection, setOpenSection] = useState(null);
//   const [isMobile, setIsMobile] = useState(false);
//   const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 756);
//     };

//     window.addEventListener("resize", handleResize);
//     handleResize();

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const handleToggle = () => setIsExpanded(!isExpanded);

//   const logout = () => {
//     localStorage.clear("token");
//     navigate("/");
//   };

//   const handleSectionToggle = (section) => {
//     setOpenSection(openSection === section ? null : section);
//   };

//   const handleNavLinkClick = () => {
//     if (isMobile) setIsExpanded(false);
//     setOpenSection(null);
//   };

//   return (
//     <>
//       {(!isMobile || isExpanded) && (
//         <div className={`sidebar__wrap ${isExpanded ? "expanded" : ""}`}>
//           <div id="sidebar" className={isExpanded ? "expand" : ""}>
//             <button className="toggle-btn" onClick={handleToggle}>
//               <div className="d-flex flex-column">
//                 <i className="bi bi-toggles align-self-center"></i>
//                 <img src={logo} className="phon-logo align-self-center" alt="Logo" />
//               </div>
//             </button>
//             <ul className="sidebar-nav">
//               <li className="sidebar-item">
//                 <NavLink
//                   to="/dashboard"
//                   className="sidebar-link"
//                   onClick={handleNavLinkClick}
//                 >
//                   <i className="bi bi-speedometer2" />
//                   <span>Dashboard</span>
//                 </NavLink>
//               </li>
//               <li className="sidebar-item">
//                 <a
//                   href="#"
//                   className="sidebar-link collapsed has-dropdown"
//                   onClick={() => handleSectionToggle("multii")}
//                 >
//                   <i className="bi bi-person-circle" />
//                   <span>Member Management</span>
//                 </a>
//                 <ul
//                   id="multii"
//                   className={`sidebar-dropdown list-unstyled ${
//                     openSection === "multii" ? "show" : "collapse"
//                   }`}
//                 >
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/Tradermember"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       All Members
//                     </NavLink>
//                   </li>
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/Paidmember"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       Paid Members
//                     </NavLink>
//                   </li>
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/Activatedmembers"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       API Activated Members
//                     </NavLink>
//                   </li>
//                 </ul>
//               </li>
//               <li className="sidebar-item">
//                 <a
//                   href="#"
//                   className="sidebar-link collapsed has-dropdown"
//                   onClick={() => handleSectionToggle("bannerManage")}
//                 >
//                   <i className="bi bi-images" />
//                   <span>Banner Management</span>
//                 </a>
//                 <ul
//                   id="banner"
//                   className={`sidebar-dropdown list-unstyled ${
//                     openSection === "bannerManage" ? "show" : "collapse"
//                   }`}
//                 >
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/bannerAdd"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       Add Banner
//                     </NavLink>
//                   </li>
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/banners"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       Banners
//                     </NavLink>
//                   </li>
//                 </ul>
//               </li>
//               {/* <li className="sidebar-item">
//                                         <NavLink to='/Tradermember' className="sidebar-link" onClick={handleNavLinkClick}>
//                                             <i className="bi bi-person" />
//                                             <span>Member</span>
//                                         </NavLink>
//                                     </li> */}
//               <li className="sidebar-item">
//                 <NavLink
//                   to="/activity"
//                   className="sidebar-link"
//                   onClick={handleNavLinkClick}
//                 >
//                   <i className="bi bi-alexa"></i>
//                   <span>Activity</span>
//                 </NavLink>
//               </li>
//               <li className="sidebar-item">
//                 <a
//                   href="#"
//                   className="sidebar-link collapsed has-dropdown"
//                   onClick={() => handleSectionToggle("auth")}
//                 >
//                   <SiFuturelearn />
//                   <span>Future Trade</span>
//                 </a>
//                 <ul
//                   id="auth"
//                   className={`sidebar-dropdown list-unstyled ${
//                     openSection === "auth" ? "show" : "collapse"
//                   }`}
//                 >
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/new_trade"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       New Trade
//                     </NavLink>
//                   </li>
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/closeposition"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       Closed Positions
//                     </NavLink>
//                   </li>
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/tradeopen"
//                       className="navlink sidebar-link collapsed dropdown-toggle"
//                       type="button"
//                       data-bs-toggle="dropdown"
//                       aria-expanded="false"
//                       //  onClick={handleNavLinkClick}
//                     >
//                       Trades
//                     </NavLink>
//                     <ul class="ms-2 dropdown-menu">
//                       <li>
//                         <Link class="dropdown-item" to="/open-trade">
//                           Open Trade
//                         </Link>
//                       </li>
//                       <li>
//                         <Link class="dropdown-item" to="/close-trade">
//                           Close Trade
//                         </Link>
//                       </li>
//                     </ul>
//                   </li>
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/Positionhistory"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       Order
//                     </NavLink>
//                   </li>
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/batch"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       Batchs
//                     </NavLink>
//                   </li>
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/profitdisbrustion"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       Profit Distribution
//                     </NavLink>
//                   </li>
//                 </ul>
//               </li>
//               <li className="sidebar-item">
//                 <a
//                   href="#"
//                   className="sidebar-link collapsed has-dropdown"
//                   onClick={() => handleSectionToggle("authi")}
//                 >
//                   <RiRefundLine />
//                   <span> Fund Transfer</span>
//                 </a>
//                 <ul
//                   id="auth"
//                   className={`sidebar-dropdown list-unstyled ${
//                     openSection === "authi" ? "show" : "collapse"
//                   }`}
//                 >
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/fundTransfer"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       Fund Tranfer
//                     </NavLink>
//                   </li>
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/fundertranction"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       Fund Tranfer History
//                     </NavLink>
//                   </li>
//                 </ul>
//               </li>
//               <li className="sidebar-item">
//                 <NavLink
//                   to="/alltranction"
//                   className="sidebar-link"
//                   onClick={handleNavLinkClick}
//                 >
//                   <i className="bi bi-alexa"></i>
//                   <span>All Transactions</span>
//                 </NavLink>
//               </li>
//               <li className="sidebar-item">
//                 <NavLink
//                   to="/bonus"
//                   className="sidebar-link"
//                   onClick={handleNavLinkClick}
//                 >
//                   <GiProfit />
//                   {/* <i className="bi bi-alexa"></i> */}
//                   <span>Bonus</span>
//                 </NavLink>
//               </li>
//               <li className="sidebar-item">
//                 <NavLink
//                   to="/tradefee"
//                   className="sidebar-link"
//                   onClick={handleNavLinkClick}
//                 >
//                   {/* <GiProfit/> */}
//                   <i className="bi bi-credit-card"></i>
//                   <span>Trade Fee</span>
//                 </NavLink>
//               </li>
//               <li className="sidebar-item">
//                 <a
//                   href="#"
//                   className="sidebar-link collapsed has-dropdown"
//                   onClick={() => handleSectionToggle("multi")}
//                 >
//                   <FaHubspot />
//                   <span>Spot Trade</span>
//                 </a>
//                 <ul
//                   id="multi"
//                   className={`sidebar-dropdown list-unstyled ${
//                     openSection === "multi" ? "show" : "collapse"
//                   }`}
//                 >
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/spottrade"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       New Trade
//                     </NavLink>
//                   </li>
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/order"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       Order
//                     </NavLink>
//                   </li>
//                   <li className="sidebar-item outcome">
//                     <NavLink
//                       to="/trader"
//                       className="navlink sidebar-link collapsed"
//                       onClick={handleNavLinkClick}
//                     >
//                       Trades
//                     </NavLink>
//                   </li>
//                 </ul>
//               </li>
//               <li>
//                 <a
//                   href="#"
//                   className="sidebar-link"
//                   onClick={() => setIsLogoutModalOpen(true)}
//                 >
//                   <i className="bi bi-box-arrow-left"></i>
//                   <span>Logout</span>
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </div>
//       )}

//       <div className="home_content">
//         <div className={`text ${isExpanded ? "sidebar-hideshow" : "text"}`}>
//           <div className="row header">
//             <div className="col-md-8 d-flex align-c">
//               <NavLink to="/" onClick={handleNavLinkClick}>
//                 {/* <img src={logo} className="logo1" alt="Logo" /> */}
//               </NavLink>

//               {isMobile && (
//                 <TiThMenu
//                   id="btn"
//                   className="toggle_btn"
//                   onClick={handleToggle}
//                 />
//               )}
//             </div>
//           </div>
//         </div>
//       </div>

//       <LogoutModal
//         isOpen={isLogoutModalOpen}
//         onCancel={() => setIsLogoutModalOpen(false)}
//         onConfirm={() => {
//           setIsLogoutModalOpen(false);
//           logout();
//         }}
//       />
//     </>
//   );
// };

// export default Sidebar;

// import React, { useState, useEffect } from "react";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import "../Assets/Css/Style.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// // import { useNavigate } from 'react-router';
// import LogoutModal from "../Auth/LogoutModal";
// import { TiThMenu } from "react-icons/ti";
// import logo from "../Assets/logo.png";
// import { SiFuturelearn } from "react-icons/si";
// import { RiRefundLine } from "react-icons/ri";
// import { FaHubspot } from "react-icons/fa";
// import { GiProfit } from "react-icons/gi";

// const Sidebar = () => {
//   const navigate = useNavigate();
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [openSection, setOpenSection] = useState(null);
//   const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 756) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };

//     window.addEventListener("resize", handleResize);

//     handleResize();

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleToggle = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const logout = () => {
//     localStorage.clear("token");
//     navigate("/");
//   };

//   const handleSectionToggle = (section) => {
//     setOpenSection(openSection === section ? null : section);
//   };
//   const handleNavLinkClick = () => {
//     if (isMobile) {
//       setIsExpanded(false);
//     }
//     setOpenSection(null);
//   };

//   return (
//     <>
//       {/* Sidebar wrapper */}
//       {(!isMobile || isExpanded) && (
//         <div className="sidebar__wrap">
//           <div className="wrapper">
//             <div id="sidebar" className={isExpanded ? "expand" : ""}>
//               <div className="d-flex">
//                 {/* <div className="sidebar-logo">
//                                     <a href="#">Future Trader</a>
//                                 </div> */}
//                 <button
//                   className="toggle-btn"
//                   type="button"
//                   onClick={handleToggle}
//                 >
//                   <i className="bi bi-toggles"></i>
//                   <img src={logo} className="phon-logo" alt="Phone Logo" />
//                 </button>
//               </div>
//               <ul className="sidebar-nav">
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/dashboard"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     <i className="bi bi-speedometer2" />
//                     <span>Dashboard</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("multii")}
//                   >
//                     <i className="bi bi-person-circle" />
//                     <span>Member Management</span>
//                   </a>
//                   <ul
//                     id="multii"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "multii" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/Tradermember"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         All Members
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/Paidmember"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Paid Members
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/Activatedmembers"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         API Activated Members
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("bannerManage")}
//                   >
//                     <i className="bi bi-images" />
//                     <span>Banner Management</span>
//                   </a>
//                   <ul
//                     id="banner"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "bannerManage" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/bannerAdd"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Add Banner
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/banners"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Banners
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 {/* <li className="sidebar-item">
//                                         <NavLink to='/Tradermember' className="sidebar-link" onClick={handleNavLinkClick}>
//                                             <i className="bi bi-person" />
//                                             <span>Member</span>
//                                         </NavLink>
//                                     </li> */}
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/activity"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     <i className="bi bi-alexa"></i>
//                     <span>Activity</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("auth")}
//                   >
//                     <SiFuturelearn />
//                     <span>Future Trade</span>
//                   </a>
//                   <ul
//                     id="auth"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "auth" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/new_trade"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         New Trade
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/closeposition"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Closed Positions
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/tradeopen"
//                         className="navlink sidebar-link collapsed dropdown-toggle"
//                         type="button"
//                         data-bs-toggle="dropdown"
//                         aria-expanded="false"
//                         //  onClick={handleNavLinkClick}
//                       >
//                         Trades
//                       </NavLink>
//                       <ul class="ms-2 dropdown-menu">
//                         <li>
//                           <Link  class="dropdown-item" to="/open-trade">
//                             Open Trade
//                           </Link>
//                         </li>
//                         <li>
//                           <Link class="dropdown-item" to="/close-trade">
//                             Close Trade
//                           </Link>
//                         </li>
//                       </ul>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/Positionhistory"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Order
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/batch"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Batchs
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/profitdisbrustion"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Profit Distribution
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("authi")}
//                   >
//                     <RiRefundLine />
//                     <span> Fund Transfer</span>
//                   </a>
//                   <ul
//                     id="auth"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "authi" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/fundTransfer"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Fund Tranfer
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/fundertranction"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Fund Tranfer History
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/alltranction"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     <i className="bi bi-alexa"></i>
//                     <span>All Transactions</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/bonus"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     <GiProfit/>
//                     {/* <i className="bi bi-alexa"></i> */}
//                     <span>Bonus</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/tradefee"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     {/* <GiProfit/> */}
//                     <i className="bi bi-credit-card"></i>
//                     <span>Trade Fee</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("multi")}
//                   >
//                     <FaHubspot />
//                     <span>Spot Trade</span>
//                   </a>
//                   <ul
//                     id="multi"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "multi" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/spottrade"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         New Trade
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/order"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Order
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/trader"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Trades
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <a
//                     href="#"
//                     className="sidebar-link"
//                     onClick={() => setIsLogoutModalOpen(true)}
//                   >
//                     <i className="bi bi-box-arrow-left"></i>
//                     <span>Logout</span>
//                   </a>
//                 </li>
//               </ul>
//               {/* </aside> */}
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Header with Toggle Button */}
//       <div className="home_content">
//         <div className={`text ${isExpanded ? "sidebar-hideshow" : "text"}`}>
//           <div className="row header">
//             <div className="col-md-8 d-flex align-c">
//               <NavLink to="/" onClick={handleNavLinkClick}>
//                 {/* <img src={logo} className="logo1" alt="Logo" /> */}
//               </NavLink>

//               {isMobile && (
//                 <TiThMenu
//                   id="btn"
//                   className="toggle_btn"
//                   onClick={handleToggle}
//                 />
//               )}
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Logout Modal */}
//       <LogoutModal
//         isOpen={isLogoutModalOpen}
//         onCancel={() => setIsLogoutModalOpen(false)}
//         onConfirm={() => {
//           setIsLogoutModalOpen(false);
//           logout();
//         }}
//       />
//     </>
//   );
// };

// export default Sidebar;

// import React, { useState, useEffect } from "react";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import "../Assets/Css/Style.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import LogoutModal from "../Auth/LogoutModal";
// import { TiThMenu } from "react-icons/ti";
// import logo from "../Assets/logo.png";
// import { SiFuturelearn } from "react-icons/si";
// import { RiRefundLine } from "react-icons/ri";
// import { FaHubspot } from "react-icons/fa";
// import { GiProfit } from "react-icons/gi";

// const Sidebar = () => {
//   const navigate = useNavigate();
//   const [isExpanded, setIsExpanded] = useState(true); // default to expanded
//   const [openSection, setOpenSection] = useState(null);
//   const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 756) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };

//     window.addEventListener("resize", handleResize);
//     handleResize();

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleToggle = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const logout = () => {
//     localStorage.clear("token");
//     navigate("/");
//   };

//   const handleSectionToggle = (section) => {
//     setOpenSection(openSection === section ? null : section);
//   };

//   const handleNavLinkClick = () => {
//     if (isMobile) {
//       setIsExpanded(false);
//     }
//     setOpenSection(null);
//   };

//   return (
//     <>
//       {/* Sidebar wrapper */}
//       {(!isMobile || isExpanded) && (
//         <div className="sidebar__wrap">
//           <div className="wrapper">
//             <div id="sidebar" className={isExpanded ? "expand" : ""}>
//               {/* Toggle Button only on Mobile */}
//               <button
//                 className="toggle-btn"
//                 type="button"
//                 onClick={handleToggle}
//               >
//                 {/* <i className="bi bi-toggles"></i> */}
//                 <img src={logo} className="phon-logo" alt="Phone Logo" />
//               </button>

//               <ul className="sidebar-nav">
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/dashboard"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     <i className="bi bi-speedometer2" />
//                     <span>Dashboard</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("multii")}
//                   >
//                     <i className="bi bi-person-circle" />
//                     <span>Member Management</span>
//                   </a>
//                   <ul
//                     id="multii"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "multii" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/Tradermember"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         All Members
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/Paidmember"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Paid Members
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/Activatedmembers"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         API Activated Members
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("bannerManage")}
//                   >
//                     <i className="bi bi-images" />
//                     <span>Banner Management</span>
//                   </a>
//                   <ul
//                     id="banner"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "bannerManage" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/bannerAdd"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Add Banner
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/banners"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Banners
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("newsManage")}
//                   >
//                     <i className="bi bi-newspaper" />
//                     <span>News Management</span>
//                   </a>
//                   <ul
//                     id="newsManage"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "newsManage" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/newsAdd"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Add News
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/news"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         News
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 {/* <li className="sidebar-item">
//                                         <NavLink to='/Tradermember' className="sidebar-link" onClick={handleNavLinkClick}>
//                                             <i className="bi bi-person" />
//                                             <span>Member</span>
//                                         </NavLink>
//                                     </li> */}
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/activity"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     <i className="bi bi-alexa"></i>
//                     <span>Activity</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("auth")}
//                   >
//                     <SiFuturelearn />
//                     <span>Future Trade</span>
//                   </a>
//                   <ul
//                     id="auth"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "auth" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/new_trade"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         New Trade
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/closeposition"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Closed Positions
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/tradeopen"
//                         className="navlink sidebar-link collapsed dropdown-toggle"
//                         type="button"
//                         data-bs-toggle="dropdown"
//                         aria-expanded="false"
//                         //  onClick={handleNavLinkClick}
//                       >
//                         Trades
//                       </NavLink>
//                       <ul class="ms-2 dropdown-menu">
//                         <li>
//                           <Link class="dropdown-item" to="/open-trade">
//                             Open Trade
//                           </Link>
//                         </li>
//                         <li>
//                           <Link class="dropdown-item" to="/close-trade">
//                             Close Trade
//                           </Link>
//                         </li>
//                       </ul>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/Positionhistory"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Order
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/batch"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Batchs
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/profitdisbrustion"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Profit Distribution
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("authi")}
//                   >
//                     <RiRefundLine />
//                     <span> Fund Transfer</span>
//                   </a>
//                   <ul
//                     id="auth"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "authi" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/fundTransfer"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Fund Transfer
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/fundertranction"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Fund Tranfer History
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/alltranction"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     <i className="bi bi-alexa"></i>
//                     <span>All Transactions</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/bonus"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     <GiProfit />
//                     {/* <i className="bi bi-alexa"></i> */}
//                     <span>Bonus</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <NavLink
//                     to="/tradefee"
//                     className="sidebar-link"
//                     onClick={handleNavLinkClick}
//                   >
//                     {/* <GiProfit/> */}
//                     <i className="bi bi-credit-card"></i>
//                     <span>Trade Fee</span>
//                   </NavLink>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link collapsed has-dropdown"
//                     onClick={() => handleSectionToggle("multi")}
//                   >
//                     <FaHubspot />
//                     <span>Spot Trade</span>
//                   </a>
//                   <ul
//                     id="multi"
//                     className={`sidebar-dropdown list-unstyled ${
//                       openSection === "multi" ? "show" : "collapse"
//                     }`}
//                   >
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/spottrade"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         New Trade
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/order"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Order
//                       </NavLink>
//                     </li>
//                     <li className="sidebar-item outcome">
//                       <NavLink
//                         to="/trader"
//                         className="navlink sidebar-link collapsed"
//                         onClick={handleNavLinkClick}
//                       >
//                         Trades
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="sidebar-item">
//                   <a
//                     href="#"
//                     className="sidebar-link "
//                     onClick={() => setIsLogoutModalOpen(true)}
//                   >
//                     <i className="bi bi-box-arrow-left"></i>
//                     <span>Logout</span>
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Main Content Area */}
//       <div className="home_content">
//         <div className={`text ${isExpanded ? "sidebar-hideshow" : "text"}`}>
//           <div className="row header">
//             <div className="col-md-8 d-flex align-c">
//               <NavLink to="/" onClick={handleNavLinkClick}>
//                 {/* Logo */}
//               </NavLink>

//               {/* Toggle Button for Mobile Screens */}
//               {isMobile && (
//                 <TiThMenu
//                   id="btn"
//                   className="toggle_btn"
//                   onClick={handleToggle}
//                 />
//               )}
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Logout Modal */}
//       <LogoutModal
//         isOpen={isLogoutModalOpen}
//         onCancel={() => setIsLogoutModalOpen(false)}
//         onConfirm={() => {
//           setIsLogoutModalOpen(false);
//           logout();
//         }}
//       />
//     </>
//   );
// };

// export default Sidebar;

import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../Assets/Css/Style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import LogoutModal from "../Auth/LogoutModal";
import { TiThMenu } from "react-icons/ti";
import logo from "../Assets/logo.png";
import { SiFuturelearn } from "react-icons/si";
import { RiRefundLine } from "react-icons/ri";
import { FaHubspot } from "react-icons/fa";
import { GiProfit } from "react-icons/gi";

const Sidebar = () => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(true);
  const [openSection, setOpenSection] = useState(null);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 756);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const logout = () => {
    localStorage.clear("token");
    navigate("/");
  };

  const handleSectionToggle = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const handleNavLinkClick = () => {
    if (isMobile) {
      setIsExpanded(false);
    }
    setOpenSection(null);
  };

  return (
    <>
      {/* Sidebar wrapper */}
      {(!isMobile || isExpanded) && (
        <div className="sidebar__wrap">
          <div className="wrapper">
            <div id="sidebar" className={isExpanded ? "expand" : ""}>
              <button
                className="toggle-btn"
                type="button"
                onClick={handleToggle}
              >
                <img src={logo} className="phon-logo" alt="Phone Logo" />
              </button>

              <ul className="sidebar-nav">
                <li className="sidebar-item">
                  <NavLink
                    to="/dashboard"
                    className="sidebar-link"
                    onClick={handleNavLinkClick}
                  >
                    <i className="bi bi-speedometer2" />
                    <span>Dashboard</span>
                  </NavLink>
                </li>

                <li className="sidebar-item">
                  <a
                    href="#"
                    className="sidebar-link has-dropdown"
                    onClick={() => handleSectionToggle("memberManagement")}
                    data-bs-toggle="collapse"
                    aria-expanded={openSection === "memberManagement"}
                  >
                    <i className="bi bi-person-circle" />
                    <span>Member Management</span>
                  </a>
                  <ul
                    className={`collapse list-unstyled ${
                      openSection === "memberManagement" ? "show" : ""
                    }`}
                  >
                    <li className="sidebar-item">
                      <NavLink
                        to="/Tradermember"
                        className="sidebar-link"
                        onClick={handleNavLinkClick}
                      >
                        All Members
                      </NavLink>
                    </li>
                    <li className="sidebar-item">
                      <NavLink
                        to="/Paidmember"
                        className="sidebar-link"
                        onClick={handleNavLinkClick}
                      >
                        Paid Members
                      </NavLink>
                    </li>
                    <li className="sidebar-item">
                      <NavLink
                        to="/Activatedmembers"
                        className="sidebar-link"
                        onClick={handleNavLinkClick}
                      >
                        API Activated Members
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="sidebar-item">
                  <a
                    href="#"
                    className="sidebar-link has-dropdown"
                    onClick={() => handleSectionToggle("bannerManage")}
                    data-bs-toggle="collapse"
                    aria-expanded={openSection === "bannerManage"}
                  >
                    <i className="bi bi-images" />
                    <span>Banner Management</span>
                  </a>
                  <ul
                    className={`collapse list-unstyled ${
                      openSection === "bannerManage" ? "show" : ""
                    }`}
                  >
                    <li className="sidebar-item">
                      <NavLink
                        to="/bannerAdd"
                        className="sidebar-link"
                        onClick={handleNavLinkClick}
                      >
                        Add Banner
                      </NavLink>
                    </li>
                    <li className="sidebar-item">
                      <NavLink
                        to="/banners"
                        className="sidebar-link"
                        onClick={handleNavLinkClick}
                      >
                        Banners
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {/* Additional sections follow similar structure */}

                <li className="sidebar-item">
                  <a
                    href="#"
                    className="sidebar-link has-dropdown"
                    onClick={() => handleSectionToggle("trade")}
                    data-bs-toggle="collapse"
                    aria-expanded={openSection === "trade"}
                  >
                    <SiFuturelearn />
                    <span>Future Trade</span>
                  </a>
                  <ul
                    className={`collapse list-unstyled ${
                      openSection === "trade" ? "show" : ""
                    }`}
                  >
                    <li className="sidebar-item">
                      <NavLink
                        to="/new_trade"
                        className="sidebar-link"
                        onClick={handleNavLinkClick}
                      >
                        New Trade
                      </NavLink>
                    </li>
                    <li className="sidebar-item">
                      <NavLink
                        to="/closeposition"
                        className="sidebar-link"
                        onClick={handleNavLinkClick}
                      >
                        Closed Positions
                      </NavLink>
                    </li>
                    <li className="sidebar-item">
                      <NavLink
                        to="/tradeopen"
                        className="sidebar-link dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                      >
                        Trades
                      </NavLink>
                      <ul className="dropdown-menu ms-2">
                        <li>
                          <Link className="dropdown-item" to="/open-trade">
                            Open Trade
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/close-trade">
                            Close Trade
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="sidebar-item outcome">
                      <NavLink
                        to="/Positionhistory"
                        className="navlink sidebar-link collapsed"
                        onClick={handleNavLinkClick}
                      >
                        Order
                      </NavLink>
                    </li>
                    <li className="sidebar-item outcome">
                      <NavLink
                        to="/batch"
                        className="navlink sidebar-link collapsed"
                        onClick={handleNavLinkClick}
                      >
                        Batchs
                      </NavLink>
                    </li>
                    <li className="sidebar-item outcome">
                      <NavLink
                        to="/profitdisbrustion"
                        className="navlink sidebar-link collapsed"
                        onClick={handleNavLinkClick}
                      >
                        Profit Distribution
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="sidebar-item">
                  <a
                    href="#"
                    className="sidebar-link has-dropdown"
                    onClick={() => handleSectionToggle("fund")}
                    data-bs-toggle="collapse"
                    aria-expanded={openSection === "fund"}
                  >
                    {/* <i className="bi bi-images" /> */}
                    <RiRefundLine />
                    <span>Fund Transfer</span>
                  </a>
                  <ul
                    className={`collapse list-unstyled ${
                      openSection === "fund" ? "show" : ""
                    }`}
                  >
                    <li className="sidebar-item">
                      <NavLink
                        to="/fundTransfer"
                        className="sidebar-link"
                        onClick={handleNavLinkClick}
                      >
                        Fund Transfer
                      </NavLink>
                    </li>
                    <li className="sidebar-item">
                      <NavLink
                        to="/fundertranction"
                        className="sidebar-link"
                        onClick={handleNavLinkClick}
                      >
                        Fund Tranfer History
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="sidebar-item">
                  <NavLink
                    to="/alltranction"
                    className="sidebar-link"
                    onClick={handleNavLinkClick}
                  >
                    <i className="bi bi-alexa"></i>
                    <span>All Transactions</span>
                  </NavLink>
                </li>
                <li className="sidebar-item">
                  <NavLink
                    to="/bonus"
                    className="sidebar-link"
                    onClick={handleNavLinkClick}
                  >
                    <GiProfit />
                    {/* <i className="bi bi-alexa"></i> */}
                    <span>Bonus</span>
                  </NavLink>
                </li>
                <li className="sidebar-item">
                  <NavLink
                    to="/tradefee"
                    className="sidebar-link"
                    onClick={handleNavLinkClick}
                  >
                    {/* <GiProfit/> */}
                    <i className="bi bi-credit-card"></i>
                    <span>Trade Fee</span>
                  </NavLink>
                </li>
                <li className="sidebar-item">
                  <a
                    href="#"
                    className="sidebar-link has-dropdown"
                    onClick={() => handleSectionToggle("spot")}
                    data-bs-toggle="collapse"
                    aria-expanded={openSection === "spot"}
                  >
                    {/* <i className="bi bi-credit-card"></i> */}
                    <FaHubspot />
                    <span>Spot Trade</span>
                  </a>
                  <ul
                    className={`collapse list-unstyled ${
                      openSection === "spot" ? "show" : ""
                    }`}
                  >
                    <li className="sidebar-item">
                      <NavLink
                        to="/spottrade"
                        className="sidebar-link"
                        onClick={handleNavLinkClick}
                      >
                        New Trade
                      </NavLink>
                    </li>
                    <li className="sidebar-item">
                      <NavLink
                        to="/order"
                        className="sidebar-link"
                        onClick={handleNavLinkClick}
                      >
                        Order
                      </NavLink>
                    </li>
                    <li className="sidebar-item">
                      <NavLink
                        to="/trader"
                        className="sidebar-link"
                        onClick={handleNavLinkClick}
                      >
                        Trades
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="sidebar-item">
                  <a
                    href="#"
                    className="sidebar-link"
                    onClick={() => setIsLogoutModalOpen(true)}
                  >
                    <i className="bi bi-box-arrow-left"></i>
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {/* Main Content Area */}
      <div className="home_content">
        <div className={`text ${isExpanded ? "sidebar-hideshow" : "text"}`}>
          <div className="row header">
            <div className="col-md-8 d-flex align-c">
              <NavLink to="/" onClick={handleNavLinkClick}></NavLink>

              {isMobile && (
                <TiThMenu
                  id="btn"
                  className="toggle_btn"
                  onClick={handleToggle}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Logout Modal */}
      <LogoutModal
        isOpen={isLogoutModalOpen}
        onCancel={() => setIsLogoutModalOpen(false)}
        onConfirm={() => {
          setIsLogoutModalOpen(false);
          logout();
        }}
      />
    </>
  );
};

export default Sidebar;
