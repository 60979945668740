import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";
import _fetch from "../config/Api";

const Newsadd = () => {
  const [news, setNews] = useState("");
  const [loading, setLoading] = useState(false);

  const handledashboard = async (e) => {
    e.preventDefault();

    if (!news) {
      toast.dismiss();
      toast.error("Please enter the details");
      return;
    }

    setLoading(true);
    try {
      const requestData = {
        news,
      };
      let response = await _fetch(
        `${base_url}api/trader/addNews`,
        "POST",
        requestData,
        {}
      );

      if (response.success) {
        setNews("");
        toast.dismiss();
        toast.success(response.message);
      } else {
        toast.error(response.message || "Failed to add news");
      }
    } catch (error) {
      console.error("Error details", error);
      toast.error("An error occurred while adding the news");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="row mt-5 d-flex align-items-center justify-content-center">
        <div className="heading text-center mb-3">
          <h3>Add News</h3>
        </div>
        <div className="col-md-7">
          <form className="user-form">
            <div className="form-group">
              <label>News Title</label>
              <input
                type="text"
                className="form-control"
                value={news}
                onChange={(e) => setNews(e.target.value)}
              />
            </div>
            <div className="row mt-3 text-center">
              <div className="col-12">
                {loading ? (
                  <ColorRing
                    height={65}
                    width={65}
                    colors={[
                      "#08d1d1",
                      "#08d1d1",
                      "#08d1d1",
                      "#08d1d1",
                      "#08d1d1",
                    ]}
                    ariaLabel="loading"
                  />
                ) : (
                  <button
                    className="p-2 bg-success text-white"
                    onClick={handledashboard}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Newsadd;
