import React from 'react'
import './Assets/Css/Style.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Login from './Auth/Login'
import Admin from './AdminPanel/Admin';
import Private from "./Routing/Private"
import PrivateLayout from './Layout/PrivateLayout';
import Positionhistory from './AdminPanel/PositionHistory'
import Public from './Routing/Public';
import Tradeopen from './AdminPanel/Tradeopen'
import NewTrade from './AdminPanel/NewTrade'
import Tradermember from './Member/Tradermember'
import Verfication from './Auth/Verfication';
import Forget from './Auth/Forget';
import Verfication_forget from './Auth/verficationforgetotp';
import Closeposition from './AdminPanel/Closeposition'
import Openview from './AdminPanel/Openview'
import Tradespotopen from './AdminPanel/Tradespotopen'
import Spottrade from './AdminPanel/Spottrade'
import Spotpositiontrade from './AdminPanel/Spotpositiontrade'
import Openspotview from './AdminPanel/Openspotview'
import Activity from './activity/Activity'
import View from './Member/View';
import FundTransfer from './AdminPanel/Fundtranfer'
import Fundertranction from './AdminPanel/Fundertranction'
import Paidmember from './AdminPanel/Paidmember'
import Activatedmembers from './AdminPanel/Activatedmembers'
import Batch from  './AdminPanel/Batch'
import Profitdisbrustion from './AdminPanel/Profitdisbrustion'
import Alltranction from './AdminPanel/Alltranction';
import Opentrade from './AdminPanel/Opentrade';
import Closetrade from './AdminPanel/Closetrade';
import Bonus from './AdminPanel/Bonus';
import Tradefee from './AdminPanel/Tradefee';
import Banneradd from './AdminPanel/Banneradd';
import Banners from './AdminPanel/Banners';
import Newsadd from './AdminPanel/Newsadd';
import News from './AdminPanel/News';
import Newsedit from './AdminPanel/Newsedit';
import Viewmemberdetails from './Member/Viewmemberdetails';
const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<Private />}>
            <Route path='/dashboard' element={<PrivateLayout><Admin /></PrivateLayout>} />
            <Route path='/tradermember' element={<PrivateLayout><Tradermember /></PrivateLayout>} />
            <Route path='/new_trade' element={<PrivateLayout><NewTrade /></PrivateLayout>} />
            <Route path='/positionhistory' element={<PrivateLayout><Positionhistory /></PrivateLayout>} />
            <Route path='/tradeopen' element={<PrivateLayout><Tradeopen /></PrivateLayout>} />
            <Route path='/closeposition' element={<PrivateLayout><Closeposition /></PrivateLayout>} />
            <Route path='/openview' element={<PrivateLayout><Openview /></PrivateLayout>} />
            <Route path='/order' element={<PrivateLayout><Tradespotopen /></PrivateLayout>} />
            <Route path='/spottrade' element={<PrivateLayout><Spottrade /></PrivateLayout>} />
            <Route path='/trader' element={<PrivateLayout><Spotpositiontrade /></PrivateLayout>} />
            <Route path='/openspotview' element={<PrivateLayout><Openspotview /></PrivateLayout>} />
            <Route path='/open-trade' element={<PrivateLayout><Opentrade /></PrivateLayout>} />
            <Route path='/close-trade' element={<PrivateLayout><Closetrade /></PrivateLayout>} />
            <Route path='/bannerAdd' element={<PrivateLayout><Banneradd /></PrivateLayout>} />
            <Route path='/banners' element={<PrivateLayout><Banners /></PrivateLayout>} />
            <Route path='/activity' element={<PrivateLayout><Activity /></PrivateLayout>} />
            <Route path='/view' element={<PrivateLayout><View /></PrivateLayout>} />
            <Route path='/bonus' element={<PrivateLayout><Bonus /></PrivateLayout>} />
            <Route path='/fundTransfer' element={<PrivateLayout><FundTransfer /></PrivateLayout>} />
            <Route path='/fundertranction' element={<PrivateLayout><Fundertranction /></PrivateLayout>} />
            <Route path='/paidmember' element={<PrivateLayout><Paidmember /></PrivateLayout>} />
            <Route path='/activatedmembers' element={<PrivateLayout><Activatedmembers /></PrivateLayout>} />
            <Route path='/batch' element={<PrivateLayout><Batch /></PrivateLayout>} />
            <Route path='/profitdisbrustion' element={<PrivateLayout><Profitdisbrustion /></PrivateLayout>} />
            <Route path='/alltranction' element={<PrivateLayout><Alltranction /></PrivateLayout>} />
            <Route path='/tradefee' element={<PrivateLayout><Tradefee /></PrivateLayout>} />
            <Route path='/newsAdd' element={<PrivateLayout><Newsadd /></PrivateLayout>} />
            <Route path='/news' element={<PrivateLayout><News /></PrivateLayout>} />
            <Route path='/newsedit' element={<PrivateLayout><Newsedit /></PrivateLayout>} />
            <Route path='/viewMemberDetail' element={<PrivateLayout><Viewmemberdetails /></PrivateLayout>} />
          </Route>

          <Route element={<Public />}>
            <Route path='/' element={<Login />} />
            <Route path='/Verfication' element={<Verfication />} />
            <Route path='/Verfication_forget' element={<Verfication_forget />} />
            <Route path='/Forget' element={<Forget />} />
          </Route>
        </Routes>
        <Toaster />
      </Router>
    </>
  )
}


export default App