import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";

const Banneradd = () => {
  const [title, setTitle] = useState("");
  const [banner, setBanner] = useState(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  function handleChange(event) {
    setBanner(event.target.files[0]);
  }

  const handledashboard = async (e) => {
    e.preventDefault();

    if (!title || !banner) {
      toast.dismiss();
      toast.error("Please enter the details");
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("banner", banner);
        const token = localStorage.getItem('token')
      const response = await fetch(`${base_url}api/trader/addBanner`, {
        method: "POST",
        headers: {
          Authorization: token, 
        },
        body: formData,
      });

      const result = await response.json();

      if (response.ok && result.success) {
        setTitle("");
        setBanner(null);
        fileInputRef.current.value = "";
        toast.dismiss();
        toast.success(result.message);
      } else {
        toast.error(result.message || "Failed to add banner");
      }
    } catch (error) {
      console.error("Error details", error);
      toast.error("An error occurred while adding the banner");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="row mt-5 d-flex align-items-center justify-content-center">
        <div className="heading text-center mb-3">
          <h3>Add Banner</h3>
        </div>
        <div className="col-md-7">
          <form className="user-form">
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Banner</label>
              <input
                type="file"
                className="form-control"
                ref={fileInputRef}
                onChange={handleChange}
              />
            </div>

            <div className="row mt-3 text-center">
              <div className="col-12">
                {loading ? (
                  <ColorRing
                    height={65}
                    width={65}
                    colors={[
                      "#08d1d1",
                      "#08d1d1",
                      "#08d1d1",
                      "#08d1d1",
                      "#08d1d1",
                    ]}
                    ariaLabel="loading"
                  />
                ) : (
                  <button
                    className="p-2 bg-success text-white"
                    onClick={handledashboard}
                  >
                    Add Banner
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Banneradd;
