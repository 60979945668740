// import React, { useEffect, useState } from 'react'
// import toast from 'react-hot-toast'
// import { Pagination } from "antd";

// import { ColorRing } from 'react-loader-spinner';
// import { _fetch } from '../config/Api'
// import { base_url } from '../config/config'
// import dateFormat from "dateformat";
// const Activity = () => {
//     const [data, setData] = useState()
//     const [currentPage, setCurrentPage] = useState(1);
//     const [pageSer, setpageSer] = useState(0);
//     const [startDate, setStartDate] = useState('');
//     const [endDate, setEndDate] = useState('');
//     // const [currency, setCurrency] = useState('');
//     const [userId, setUserId] = useState('');
//     const handledashboard = async (page = 1, pageSize = 10) => {
//         try {
//             const skip = (page - 1) * pageSize;
//             setpageSer(skip);
//             const requestData = {
//                 limit: pageSize,
//                 skip: skip,
//                 from: startDate,
//                 to: endDate,
//                 // currency: currency,
//                 uid: userId,
//             };

//             let response = await _fetch(`${base_url}api/trader/activitys`, 'post', requestData);

//             if (response.success === true) {
//                 setData(response);
//             } else {
//                 toast.error(response.message);
//             }
//         } catch (error) {
//             console.log("Error details", error);
//         }
//     }

//     useEffect(() => {
//         handledashboard(currentPage, 10);
//     }, [currentPage ,startDate, endDate, userId]);
//     const handlePaginationChange = (pageOne) => {
//         setCurrentPage(pageOne);

//     };
//     const resetFilters = () => {
//         console.log('function call');

//         setStartDate('');
//         setEndDate('');
//         // setCurrency('');
//         setUserId('');
//         setCurrentPage(1);
//         // Reset filtered data to show all data again
//         handledashboard(currentPage, 10); // Optionally fetch all data again
//     };

//     const records = () => {
//         if (data && data?.data && data?.data?.length > 0) {
//             return data?.data?.map((row, i) => {
//                 const serialNumber = pageSer + i + 1;
//                 return (
//                     <tr className="table-row" key={i}>
//                         <td className="tabel-text w inter">{serialNumber}</td>
//                         <td className="tabel-text w inter">{row?.userId?.name}</td>
//                         <td className="tabel-text w inter text-success">{row?.userId?.userId}</td>
//                         <td className="tabel-text w inter">{row?.activity}</td>
//                         <td className="tabel-text w inter">{row?.discription}</td>
//                         <td className="tabel-text w inter text-danger">{row?.message}</td>

//                         <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>
//                         {/* <td>{dateFormat(row.updatedAt, "yyyy-mm-dd, hh:mm:ss TT")}</td> */}

//                     </tr>
//                 );
//             });
//         } else {
//             return (
//                 <tr>
//                     <td colSpan="13">Data Not Found</td>
//                 </tr>
//             );
//         }
//     };

//     // const records = () => {
//     //     if (data && data?.data && data?.data?.length > 0) {
//     //         return data?.data?.map((row, i) => {
//     //             const serialNumber = pageSer + i + 1;
//     //             return (
//     //                 <div key={i} className="container mt-5 p-1">
//     //                     <div className="card border-primary">
//     //                         {/* <div className="card-header bg-white d-flex justify-content-between align-items-center">
//     //                             <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
//     //                         </div> */}

//     //                         <div className="card-body p-2">
//     //                             <div className="row p-0">
//     //                                 <div className="col-md-4">
//     //                                     <dl className="row mb-0 positionRow">
//     //                                         <dt className="col-6">Activity:</dt>
//     //                                         <dd className="col-6">{row?.activity}</dd>
//     //                                         <dt className="col-6">Name:</dt>
//     //                                         <dd className="col-6">{row?.userId?.name}</dd>
//     //                                         {/* <dt className="col-sm-5">User Id:</dt>
//     //                                         <dd className="col-sm-7">{row?.userId?.userId}</dd>
//     //                                         <dt className="col-sm-5">Message:</dt>
//     //                                         <dd className="col-sm-7">{row?.message}</dd>
//     //                                         <dt className="col-sm-5">Date</dt>
//     //                                         <dd className="col-sm-7">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd> */}
//     //                                         {/* <dt className="col-sm-5"> Update Date</dt>
//     //                                         <dd className="col-sm-7"> {dateFormat(row.updatedAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd> */}

//     //                                     </dl>
//     //                                 </div>
//     //                                 <div className="col-md-4">
//     //                                     <dl className="row mb-0 positionRow">
//     //                                         {/* <dt className="col-sm-5">Activity:</dt>
//     //                                         <dd className="col-sm-7">{row?.activity}</dd>
//     //                                         <dt className="col-sm-5">Name:</dt>
//     //                                         <dd className="col-sm-7">{row?.userId?.name}</dd> */}
//     //                                         <dt className="col-6">User Id:</dt>
//     //                                         <dd className="col-6">{row?.userId?.userId}</dd>
//     //                                         <dt className="col-6">Message:</dt>
//     //                                         <dd className="col-6">{row?.message}</dd>
//     //                                         {/* <dt className="col-sm-5">Date</dt>
//     //                                         <dd className="col-sm-7">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd> */}
//     //                                         {/* <dt className="col-sm-5"> Update Date</dt>
//     //                                         <dd className="col-sm-7"> {dateFormat(row.updatedAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd> */}

//     //                                     </dl>
//     //                                 </div>
//     //                                 <div className="col-md-4">
//     //                                     <dl className="row mb-0 positionRow">
//     //                                         {/* <dt className="col-sm-5">Activity:</dt>
//     //                                         <dd className="col-sm-7">{row?.activity}</dd>
//     //                                         <dt className="col-sm-5">Name:</dt>
//     //                                         <dd className="col-sm-7">{row?.userId?.name}</dd>
//     //                                         <dt className="col-sm-5">User Id:</dt>
//     //                                         <dd className="col-sm-7">{row?.userId?.userId}</dd>
//     //                                         <dt className="col-sm-5">Message:</dt>
//     //                                         <dd className="col-sm-7">{row?.message}</dd> */}
//     //                                         <dt className="col-6">Date</dt>
//     //                                         <dd className="col-6">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
//     //                                         {/* <dt className="col-sm-5"> Update Date</dt>
//     //                                         <dd className="col-sm-7"> {dateFormat(row.updatedAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd> */}

//     //                                     </dl>
//     //                                 </div>

//     //                             </div>
//     //                         </div>
//     //                     </div>
//     //                 </div>
//     //             );
//     //         });
//     //     } else {
//     //         return <div>Data Not Found</div>;
//     //     }
//     // };
//     return (
//         <>
//             {/* <div className="section2">

//                 <div className="table-sec">
//                     <div className="transactions">

//                         {records()}
//                     </div>
//                     <div className="pagination">
//                         <Pagination
//                             style={{ cursor: "pointer" }}
//                             className="mx-auto"
//                             current={currentPage || 1}
//                             total={data?.count || 0}
//                             pageSize={10}
//                             onChange={handlePaginationChange}
//                             showSizeChanger={false}
//                             showQuickJumper={false}
//                         />

//                     </div>

//                 </div>
//             </div> */}

//             <div className="container container-trade p-0">
//                 {/* <div className=""> */}
//                 <div className="container container-trade2 mt-2 p-1">
//                     <div className="transactions">

//                     <div className="container container-trade2 mt-2 p-1">
//                             <div className="card back-card">
//                                 <div className='main-12'>
//                                     <div className='row'>

//                                         <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
//                                             <input type='date' className='form-control' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
//                                         </div>
//                                         <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
//                                             <input type='date' className='form-control' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
//                                         </div>
//                                         {/* <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
//                                             <select class="form-select" aria-label="Default select example">
//                                                 <option selected>UserId</option>
//                                                 <option value="1">Email</option>
//                                                 <option value="2">Phone</option>
//                                                 <option value="3">sponserId</option>
//                                             </select>
//                                         </div> */}

//                                         <div className='col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1'>
//                                             <div className='search-btn'>
//                                                 <input type="text" placeholder="User Id Search" value={userId} onChange={(e) => setUserId(e.target.value)} />
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1'>
//                                             <div className='reset-btn'>
//                                                 <button className='apply-btn' onClick={() => handledashboard(1, 10)}>Apply Filters</button>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1'>
//                                             <div className='place-2'>
//                                                 <button  onClick={resetFilters} className="btn btn-secondary">Reset Filters</button>
//                                             </div>
//                                         </div>

//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         {/* {records()} */}
//                         <div className="card border-primary mt-2">
//                             <table class="table table-responsive">
//                                 <thead>
//                                     <tr>
//                                         <th scope="col">#</th>
//                                         <th scope="col">UserId</th>
//                                         <th scope="col">Name</th>
//                                         <th scope="col">Activity</th>
//                                         <th scope="col">Discription</th>
//                                         <th scope="col">Message</th>
//                                         <th scope="col">Date</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>

//                                     {records()}
//                                 </tbody>
//                             </table>
//                         </div>

//                         <div className="pagination mt-4">
//                             <Pagination
//                                 style={{ cursor: "pointer" }}
//                                 className="mx-auto"
//                                 current={currentPage || 1}
//                                 total={data?.count || 0}
//                                 pageSize={10}
//                                 onChange={handlePaginationChange}
//                                 showSizeChanger={false}
//                                 showQuickJumper={false}
//                             />

//                         </div>
//                     </div>

//                 </div>
//             </div>
//         </>
//     )
// }

// export default Activity

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Pagination } from "antd";
import { ColorRing } from "react-loader-spinner";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import dateFormat from "dateformat";

const Activity = () => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setPageSer] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    userId: "",
    name: "",
  });

  const handledashboard = async (page = 1, pageSize = 10, filters = {}) => {
    try {
      const skip = (page - 1) * pageSize;
      setPageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        from: filters.startDate,
        to: filters.endDate,
        uid: filters.userId,
        name: filters.name,
      };

      let response = await _fetch(
        `${base_url}api/trader/activitys`,
        "post",
        requestData
      );

      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
  };

  const applyFilters = () => {
    setFilters({
      startDate,
      endDate,
      userId,
      name,
    });
  };

  // Reset filters to default state
  const resetFilters = () => {
    setStartDate("");
    setEndDate("");
    setUserId("");
    setName("");
    setFilters({
      startDate: "",
      endDate: "",
      userId: "",
      name: "",
    });
    setCurrentPage(1);
    handledashboard(1, 10, {}); // Fetch all data again
  };

  useEffect(() => {
    handledashboard(currentPage, 10, filters);
  }, [currentPage, filters]); // Only trigger when filters or currentPage change

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <tr className="table-row" key={i}>
            <td className="tabel-text w inter">{serialNumber}</td>
            <td className="tabel-text w inter text-success">
              {row?.userId?.userId}
            </td>
            <td className="tabel-text w inter">{row?.userId?.name}</td>
            <td className="tabel-text w inter">{row?.activity}</td>
            <td className="tabel-text w inter">{row?.discription}</td>
            <td className="tabel-text w inter text-danger">{row?.message}</td>
            <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="7">Data Not Found</td>
        </tr>
      );
    }
  };

  return (
    <>
      <div className="container container-trade p-0">
        <div className="container container-trade2 mt-2 p-1">
          <div className="transactions">
            <div className="container container-trade2 mt-2 p-1">
              <div className="card back-card">
                <div className="main-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1">
                      <input
                        type="date"
                        className="form-control m-auto"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1">
                      <input
                        type="date"
                        className="form-control m-auto"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                      <div className="search-btn">
                        <input
                          type="text"
                          placeholder="User Id Search"
                          value={userId}
                          onChange={(e) => setUserId(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                      <div className="search-btn">
                        <input
                          type="text"
                          placeholder="Name Search"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                      <div className="reset-btn">
                        <button className="apply-btn" onClick={applyFilters}>
                          Apply Filters
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                      <div className="place-2">
                        <button
                          onClick={resetFilters}
                          className="btn btn-secondary"
                        >
                          Reset Filters
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="text-right1 p-3 text-primary">
                Total Activities: {data?.count}
              </h4>
            </div>

            <div className="card border-primary mt-2">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">UserId</th>
                    <th scope="col">Name</th>
                    <th scope="col">Activity</th>
                    <th scope="col">Discription</th>
                    <th scope="col">Message</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>{records()}</tbody>
              </table>
            </div>

            <div className="pagination mt-4">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Activity;
