import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";
import dateFormat from "dateformat";
import { Pagination } from "antd";

const Positionhistory = () => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [currencyData, setCurrencyData] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [sponserId, SetSponserId] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [currency, setCurrency] = useState("");
  const [leverage, setLeverage] = useState("");
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    userId: "",
    email: "",
    name: "",
    phonenumber: "",
    sponserId: "",
    currency: "",
    leverage: "",
  });
  const applyFilters = () => {
    setFilters({
      startDate,
      endDate,
      userId,
      email,
      phonenumber,
      sponserId,
      currency,
      leverage,
      name,
    });
  };
  const resetFilters = () => {
    setStartDate("");
    setEndDate("");
    setUserId("");
    setEmail("");
    setName("");
    setPhonenumber("");
    SetSponserId("");
    setCurrency("");
    setLeverage("");
    setFilters({
      startDate: "",
      endDate: "",
      userId: "",
      email: "",
      name: "",
      phonenumber: "",
      sponserId: "",
      currency: "",
      leverage: "",
    });
    setCurrentPage(1);
    handledashboard(1, 10, {}); // Fetch all data again
  };
  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        type: "FUTURE",
        from: filters.startDate,
        name: filters.name,
        to: filters.endDate,
        uid: filters.userId,
        currency: filters.currency,
        leverage: parseInt(filters.leverage),
      };

      let response = await _fetch(
        `${base_url}api/trader/orders`,
        "post",
        requestData
      );

      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  const fetchCurrencyData = async () => {
    try {
      let response = await _fetch(`${base_url}api//user/tokens`, "get");
      if (response.success === true) {
        setCurrencyData(response.data);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage, filters]);

  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
  };

  useEffect(() => {
    fetchCurrencyData();
  }, []);

  // const records = () => {
  //   if (data && data?.data && data?.data?.length > 0) {
  //     return data?.data?.map((row, i) => {
  //       const serialNumber = pageSer + i + 1;
  //       return (
  //         <tr className="table-row" key={i}>
  //           <td className="tabel-text w inter">{serialNumber}</td>
  //           <td className="tabel-text w inter">{row?.userId?.userId}</td>
  //           <td className="tabel-text w inter">{row?.userId?.name}</td>

  //           <td className="tabel-text w inter">{row?.orderId}</td>
  //           <td className="tabel-text w inter">{row?.symbol}</td>
  //           <td className="tabel-text w inter">{row?.status}</td>
  //           <td className="tabel-text w inter">{row?.price}</td>
  //           <td className="tabel-text w inter">{row?.origQty}</td>
  //           <td className="tabel-text w inter">{row?.type}</td>
  //           <td className="tabel-text w inter">{row?.side}</td>
  //           <td className="tabel-text w inter">{row?.quantity}</td>
  //           <td className="tabel-text w inter">{row?.avgPrice}</td>
  //           <td className="tabel-text w inter">{row?.executedQty}</td>
  //           <td className="tabel-text w inter">{row?.cumQty}</td>
  //           <td className="tabel-text w inter">{row?.cumQuote}</td>
  //           <td className="tabel-text w inter">{row?.timeInForce}</td>
  //           <td className="tabel-text w inter">{row?.side}</td>
  //           <td className="tabel-text w inter">{row?.positionSide}</td>
  //           <td className="tabel-text w inter">{row?.stopPrice}</td>
  //           <td className="tabel-text w inter">{row?.workingType}</td>
  //           <td className="tabel-text w inter">{row?.origType}</td>
  //           <td className="tabel-text w inter">{row?.priceMatch}</td>
  //           <td className="tabel-text w inter">{row?.selfTradePreventionMode}</td>
  //           <td className="tabel-text w inter">{row?.goodTillDate}</td>
  //           <td className="tabel-text w inter">{row?.batchId}</td>
  //           <td className="tabel-text w inter">{row?.fee}</td>
  //           <td className="tabel-text w inter">{row?.exchangeName}</td>
  //           <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>

  //         </tr>
  //       );
  //     });
  //   } else {
  //     return (
  //       <tr>
  //         <td colSpan="13">Data Not Found</td>
  //       </tr>
  //     );
  //   }
  // };
  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return (
        <table className="table table-bordered table-striped mt-5">
          <thead className="table-primary">
            <tr>
              <th>Serial No.</th>
              <th>Name</th>
              <th>User Id</th>
              <th>Order Id</th>
              <th>Symbol</th>
              <th>Status</th>
              <th>Cum Qty</th>
              <th>Cum Quote</th>
              {/* <th>Time In Force</th> */}
              <th>Orig Qty</th>
              <th>Type</th>
              <th>Side</th>
              <th>Quantity</th>
              <th>Avg Price</th>
              <th>Executed Qty</th>
              <th>Position Side</th>
              <th>Stop Price</th>
              <th>Working Type</th>
              <th>Orig Type</th>
              <th>Good Till Date</th>
              <th>Batch Id</th>
              <th>Price Match</th>
              <th>SelfTradeMode</th>
              <th>Fee</th>
              <th>Leverage</th>
              <th>Exchange Name</th>
              <th>Date</th>
              <th>Close Status</th>
            </tr>
          </thead>
          <tbody>
            {data.data.map((row, i) => {
              const serialNumber = pageSer + i + 1;
              return (
                <tr key={i}>
                  <td>{serialNumber}</td>
                  <td className="text-success">{row?.userId?.name}</td>
                  <td>{row?.userId?.userId}</td>
                  <td className="text-secondary">{row?.orderId}</td>
                  <td>{row?.symbol}</td>
                  <td className="text-success">{row?.status}</td>
                  <td>{row?.cumQty}</td>
                  <td>{row?.cumQuote}</td>
                  {/* <td>{row?.timeInForce}</td> */}
                  <td>{row?.origQty}</td>
                  <td>{row?.type}</td>
                  <td>{row?.side}</td>
                  <td className="text-danger">{row?.quantity}</td>
                  <td>{row?.avgPrice}</td>
                  <td className="text-secondary">{row?.executedQty}</td>
                  <td>{row?.positionSide}</td>
                  <td>{row?.stopPrice}</td>
                  <td>{row?.workingType}</td>
                  <td>{row?.origType}</td>
                  <td>{row?.goodTillDate}</td>
                  <td className="text-primary">{row?.batchId}</td>
                  <td>{row?.priceMatch}</td>
                  <td>{row?.selfTradePreventionMode}</td>
                  <td>{row?.fee}</td>
                  <td>{row?.leverage}</td>
                  <td className="text-warning">{row?.exchangeName}</td>
                  <td>
                    {dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}
                  </td>
                  <td>
                    {row?.closeStatus ? (
                      <span className="text-danger">Closed</span>
                    ) : (
                      <span className="text-success">Open</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return <div>Data Not Found</div>;
    }
  };

  return (
    <>
      <div className="container container-trade p-0">
        {/* <div className=""> */}
        <div className="container container-trade2 mt-2 p-1">
          <div className="card back-card">
            <div className="main-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                  <input
                    type="date"
                    className="form-control"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                  <input
                    type="date"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                {/* <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>UserId</option>
                                                <option value="1">Email</option>
                                                <option value="2">Phone</option>
                                                <option value="3">sponserId</option>
                                            </select>
                                        </div> */}
                {/* <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="email"
                      placeholder="email Search"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div> */}

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="text"
                      placeholder="User Id Search"
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="text"
                      placeholder="Name Search"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="number"
                      placeholder="phone number Search"
                      value={phonenumber}
                      onChange={(e) => setPhonenumber(e.target.value)}
                    />
                  </div>
                </div> */}

                {/* <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="text"
                      placeholder="sponser Id Search"
                      value={sponserId}
                      onChange={(e) => SetSponserId(e.target.value)}
                    />
                  </div>
                </div> */}
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setCurrency(e.target.value)}
                    value={currency}
                  >
                    {console.log(currencyData)}
                    <option value={""}>Select Currency</option>
                    {currencyData && currencyData.length > 0
                      ? currencyData.map((currency, index) => (
                          <option key={index} value={currency.token}>
                            {currency.token}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="number"
                      placeholder="Leverage"
                      value={leverage}
                      onChange={(e) => setLeverage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                  <div className="reset-btn">
                    <button className="apply-btn" onClick={applyFilters}>
                      Apply Filters
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                  <div className="place-2">
                    <button
                      onClick={resetFilters}
                      className="btn btn-secondary"
                    >
                      Reset Filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 className="text-right1 p-3 text-primary">
            Total Orders: {data?.count}
          </h4>
        </div>
        <div className="container container-trade2 mt-2 p-1">
          <div className="transactions">{records()}</div>
          <div className="pagination">
            <Pagination
              style={{ cursor: "pointer" }}
              className="mx-auto"
              current={currentPage || 1}
              total={data?.count || 0}
              pageSize={10}
              onChange={handlePaginationChange}
              showSizeChanger={false}
              showQuickJumper={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Positionhistory;
