// import React, { useEffect, useState } from 'react'
// import toast from 'react-hot-toast'
// import { _fetch } from '../config/Api'
// import { base_url } from '../config/config'
// import { ColorRing } from 'react-loader-spinner';
// const FundTransfer = () => {
//   const [uid, setUid] = useState('');
//   const [amount, setAmount] = useState(0);

//   const [loading, setLoading] = useState(false);

//   const handledashboard = async (e) => {
//     e.preventDefault();

//     if (!uid || !amount) {
//       toast.dismiss()
//       toast.error("Please enter the details");
//       return;
//     }
//     setLoading(true);
//     try {
//       const requestData = {
//         uid: uid,
//         amount: amount,
//       };

//       let response = await _fetch(`${base_url}api/trader/fundTransfer`, 'post', requestData);

//       if (response.success === true) {
//         setUid('')
//         setAmount('')

//         toast.dismiss()
//         toast.success(response.message);
//         // setData(response);
//       } else {
//         toast.error(response.message);
//       }
//     } catch (error) {
//       console.log("Error details", error);
//     } finally {
//       setLoading(false);
//     }
//   }

//   return (
//     <>
//       <div className="row mt-5 d-flex align-items-center justify-content-center">
//         <div className="heading text-center mb-3">
//           <h3>Fund Transfer</h3>
//         </div>
//         <div className='col-md-7'>
//           <form className='' >
//             <div className='form-group'>
//               <label className=''>User Id</label>
//               <input type='text' className='form-control' value={uid} onChange={(e) => setUid(e.target.value)} />
//               {/* <label className=''>Mode</label>
//                             <select

//                                 className="form-control input form-select"
//                                 aria-label="Default select example"
//                                 value={symbol}
//                                 onChange={(e) => setSymbol(e.target.value)}
//                             >
//                                 <option >Symbol</option>
//                                 <option value={'SOLUSDT'}>SOLUSDT</option>
//                             </select> */}
//             </div>

//             <div className='form-group'>
//               <label className=''>Amount</label>

//               <input type='number' className='form-control' value={amount} onChange={(e) => setAmount(e.target.value)} />
//             </div>

//             <div className='row mt-3 text-center'>
//               <div className='col-12'>
//                 {loading ? (
//                   <ColorRing
//                     height={65}
//                     width={65}
//                     colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']}
//                     ariaLabel="loading"
//                   />
//                 ) : (
//                   <button className='p-2 bg-success text-white' onClick={handledashboard} >Place Order</button>
//                 )}
//               </div>

//             </div>
//           </form>
//         </div>

//       </div>
//     </>
//   )
// }

// export default FundTransfer

import React, { useState } from "react";
import toast from "react-hot-toast";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";

const FundTransfer = () => {
  const [uid, setUid] = useState("");
  const [amount, setAmount] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const handledashboard = async (e) => {
    e.preventDefault();

    if (!uid || !amount || !otp) {
      toast.dismiss();
      toast.error("Please enter the details");
      return;
    }
    setLoading(true);
    try {
      const requestData = {
        uid: uid,
        amount: amount,
        otp: otp,
      };

      let response = await _fetch(
        `${base_url}api/trader/fundTransfer`,
        "post",
        requestData
      );

      if (response.success === true) {
        setUid("");
        setAmount("");
        setName("");
        setEmail("");
        setOtp("");
        toast.dismiss();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUIDChange = async (e) => {
    const newUid = e.target.value;
    setUid(newUid);
    if (newUid) {
      try {
        const requestData = { uid: newUid };
        let response = await _fetch(
          `${base_url}api/trader/fetchDetails`,
          "post",
          requestData
        );

        if (response.success === true) {
          setName(response.data.name);
          setEmail(response.data.email);
          // toast.dismiss();
          // toast.success(response.message);
        } else {
          setName("");
          setEmail("");
          // toast.dismiss()
          // toast.error(response.message);
        }
      } catch (error) {
        console.log("Error details", error);
      }
    } else {
      setName("");
      setName("");
    }
  };

  const handleOtp=async()=>{
    try {
      let response = await _fetch(
        `${base_url}api/trader/fundTransferOtp`,
        "get",
      );

      if (response.success === true) {
        toast.dismiss();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }


  return (
    <>
      <div className="row mt-5 d-flex align-items-center justify-content-center">
        <div className="heading text-center mb-3">
          <h3>Fund Transfer</h3>
        </div>
        <div className="col-md-7">
          <form className="user-form">
            <div className="form-group">
              <label>User Id</label>
              <input
                type="text"
                className="form-control"
                value={uid}
                onChange={handleUIDChange} // Call this on change
              />
              {name && email && (
                <div className="mt-2">
                  Name: {name} <br />
                  Email: {email}
                </div>
              )}{" "}
              {/* Display the fetched name directly under the UID input */}
            </div>

            <div className="form-group">
              <label>Otp</label>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <button className="position-absolute top-0 btn btn-primary" type="button" onClick={handleOtp} style={{right:9, marginTop:"9px"}}>OTP</button>
              </div>
            </div>

            <div className="form-group">
              <label>Amount</label>
              <input
                type="number"
                className="form-control"
                min={0}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>

            <div className="row mt-3 text-center">
              <div className="col-12">
                {loading ? (
                  <ColorRing
                    height={65}
                    width={65}
                    colors={[
                      "#08d1d1",
                      "#08d1d1",
                      "#08d1d1",
                      "#08d1d1",
                      "#08d1d1",
                    ]}
                    ariaLabel="loading"
                  />
                ) : (
                  <button
                    className="p-2 bg-success text-white"
                    onClick={handledashboard}
                  >
                    Place Order
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FundTransfer;
