import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";
import { Alert, Pagination } from "antd";
const News = () => {
  const [data, setData] = useState();
  const [spotBalances, setSpotBalances] = useState({});
  const [futureBalances, setFutureBalances] = useState({});
  const [loading, setLoading] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [sponserId, SetSponserId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currency, setCurrency] = useState("");
  const [userId, setUserId] = useState("");
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    userId: "",
    email: "",
    phonenumber: "",
    sponserId: "",
    name: "",
  });

  const applyFilters = () => {
    setFilters({
      startDate,
      endDate,
      userId,
      email,
      phonenumber,
      sponserId,
      name,
    });
  };

  const handleDelete = async (id) => {
    try {
      const requestData = {
        id,
      };
      let response = await _fetch(
        `${base_url}api/trader/deleteNews`,
        "post",
        requestData
      );

      if (response.success === true) {
        toast.dismiss();
        toast.success(response.message);
        const pageSize=10;
        handledashboard(currentPage, pageSize);
      } else {
        toast.dismiss();
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Error fetching banners");
      console.log("Error details", error);
    } finally {
      setLoading(false);
    }
  };

  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
      };
      let response = await _fetch(
        `${base_url}api/trader/getNews`,
        "get",
        requestData
      );

      if (response.success === true) {
        setData(response);
      } else {
        toast.dismiss();
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  const getbalance = async (_id, type) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [_id]: { ...prevLoading[_id], [type]: true },
    }));

    try {
      const requestData = {
        userId: _id,
        type: type,
      };

      let response = await _fetch(
        `${base_url}api/trader/walletBalance`,
        "post",
        requestData
      );
      console.log("response", response);

      if (response.success === true) {
        if (type === "SPOT") {
          setSpotBalances((prevBalances) => ({
            ...prevBalances,
            [_id]: response.data,
          }));
        } else if (type === "FUTURE") {
          setFutureBalances((prevBalances) => ({
            ...prevBalances,
            [_id]: response.data,
          }));
        }
        toast.dismiss();
        toast.success(`${type} balance fetched successfully`);
      } else {
        toast.dismiss();
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error details:", error);
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        [_id]: { ...prevLoading[_id], [type]: false },
      }));
    }
  };

  const handleUpdate=async(news, id)=>{
    if(!news || !id){
        toast.dismiss();
        toast.error("News is empty")
        return;
    }
    navigate( '/newsedit',
        {
          state: {
            news,
            id,
          }
        })

  }

  useEffect(() => {
    handledashboard(currentPage, 10, filters);
  }, [currentPage, filters]);

  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
  };

  const handleStatusChange = (userId, selectedValue) => {
    let url = "";

    switch (selectedValue) {
      case "openview":
        url = `/View?id=${userId}`;
        break;

      default:
        return;
    }

    navigate(url);
  };

  const resetFilters = () => {
    setStartDate("");
    setEndDate("");
    setUserId("");
    setName("");
    setEmail("");
    setPhonenumber("");
    SetSponserId("");
    setFilters({
      startDate: "",
      endDate: "",
      userId: "",
      email: "",
      phonenumber: "",
      name: "",
      sponserId: "",
    });
    setCurrentPage(1);
    handledashboard(1, 10, {}); // Fetch all data again
  };

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <tr>
            <th scope="row">{serialNumber}</th>
            {/* <td>{row?.userId}</td> */}
            <td>{row?.news}</td>
            <td>
              <button className="btn btn-warning" type="button" onClick={()=>handleUpdate(row?.news, row?._id)}>
                Update
              </button>
            </td>
            <td>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => handleDelete(row?._id)}
              >
                Delete
              </button>
            </td>
            {/* <td>{dateFormat(row.createdAt, "yyyy-mm-dd")}</td> */}
          </tr>
        );
      });
    } else {
      return <div>Data Not Found</div>;
    }
  };

  return (
    <>
      <div className="section2">
        <div className="container container-trade p-0">
          <div className="">
            <h2 className="text-center text-primary">News</h2>
            {/* <div className="container container-trade2 mt-2 p-1">
              <div className="card back-card">
                <div className="main-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                      <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                      <div className="search-btn">
                        <input
                          type="email"
                          placeholder="email Search"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                      <div className="search-btn">
                        <input
                          type="text"
                          placeholder="User Id Search"
                          value={userId}
                          onChange={(e) => setUserId(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                      <div className="search-btn">
                        <input
                          type="text"
                          placeholder="Name Search"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                      <div className="search-btn">
                        <input
                          type="number"
                          placeholder="phone number Search"
                          value={phonenumber}
                          onChange={(e) => setPhonenumber(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                      <div className="search-btn">
                        <input
                          type="text"
                          placeholder="sponser Id Search"
                          value={sponserId}
                          onChange={(e) => SetSponserId(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                      <div className="reset-btn">
                        <button className="apply-btn" onClick={applyFilters}>
                          Apply Filters
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                      <div className="place-2">
                        <button
                          onClick={resetFilters}
                          className="btn btn-secondary"
                        >
                          Reset Filters
                        </button>
                      </div>
                    </div>
                  </div>
             
                </div>
              </div>
              <h4 className="text-right1 p-3 text-primary">
                Total Members: {data?.count}
              </h4>
            </div> */}
            <div className="container container-trade2 mt-2 p-1">
              <div className="transactions dist">
                <div className="card border-primary">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">News</th>
                        <th scope="col">Update</th>
                        <th scope="col">Delete</th>
                        {/* <th scope="col">view </th> */}
                      </tr>
                    </thead>
                    <tbody>{records()}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="pagination mt-3">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
