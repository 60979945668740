import React, { useState } from "react";
import "../Assets/Css/Style.css";
import { NavLink, useNavigate } from "react-router-dom";
import { base_url } from "../config/config";
import _fetch from "../config/Api";
import toast from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import logo from "../Assets/logo.png";
import businessman from "../Assets/businessman.jpg";
const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.dismiss();
      toast.error("Please enter the details");
      return;
    }
    setLoading(true);
    try {
      const requestData = {
        email,
        password,
      };
      let response = await _fetch(
        `${base_url}api/trader/signIn`,
        "POST",
        requestData,
        {}
      );

      if (response.success === true) {
        setEmail("");
        setPassword("");
        // localStorage.setItem("token", response.token)
        toast.dismiss();
        toast.success(response.message);
        navigate("/Verfication", { state: { email: email } });
      } else {
        toast.dismiss();
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error details:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="conatiner-fluid">
        <div className="d-flex justify-content-center">
          <div className="login-page ">
            <div className="heading text-center ">
              <NavLink to="/">
                <img className="welcome" src={logo} alt="" />
              </NavLink>
              <img className="image_border mt-3" src={businessman} alt="..." />
            </div>
            <h2 className="text-center text-primary mb-3">Trader Login</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Email{" "}
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  autoComplete="off"
                  aria-describedby="emailHelp"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <div className="forget fw-bold mt-2 pt-1 mb-0 text-end">
                  <div className="link-danger">
                    <NavLink to="/Verfication_forget" className="link-danger">
                      Forgot Password?
                    </NavLink>
                  </div>
                </div>
              </div>
              {/* <button type="submit" className="btn btn-primary" > */}
              {loading ? (
                <ColorRing
                  height={65}
                  width={65}
                  colors={[
                    "#08d1d1",
                    "#08d1d1",
                    "#08d1d1",
                    "#08d1d1",
                    "#08d1d1",
                  ]}
                  ariaLabel="loading"
                />
              ) : (
                <button
                  type="submit"
                  className="btn  btn-primary d-block m-auto"
                >
                  {" "}
                  submit
                </button>
              )}
              {/* </button> */}

              {/* <div>
                <p className="small fw-bold mt-2 pt-1 mb-0">
                  Don't have an account? <NavLink to="" className="link-danger">Sign Up</NavLink>
                </p>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
